import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import { User } from 'dto/user';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import { api, getAuthStorage } from '@fleet/shared';

export const getCurrentUser = createAsyncThunk<User>(
  'common/getCurrentUser',
  async (_, { getState, dispatch }) => {
    const { data: user } = await api.get<User>(
      `/users/${getAuthStorage().profile.sub}`
    );
    const { organizations } = user;
    const prevBusinessEntityId = selectCurrentBusinessEntityId(getState());
    const { 0: defaultBusinessEntity } = organizations;
    const currentBusinessEntity =
      organizations.find(({ id }) => id === prevBusinessEntityId) ??
      defaultBusinessEntity;

    if (currentBusinessEntity)
      dispatch(setCurrentBusinessEntity(currentBusinessEntity.id));
    return user;
  }
);

export const setCurrentBusinessEntity = createAction<string>(
  'common/setCurrentBusinessEntity'
);
