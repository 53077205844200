import { userSelector } from 'features/user/userSelectors';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const PASSWORD_MINIMAL_LENGTH = 8;
export const PASSWORD_MINIMAL_COMPLEXITY = 3;

export const PASSWORD_REQUIREMENTS = [
  'digit',
  'lowercaseLetter',
  'uppercaseLetter',
  'specialCharacter',
] as const;

export type PasswordRequirements = typeof PASSWORD_REQUIREMENTS[number];

const PASSWORD_REQUIREMENTS_REG_EXPS: Record<PasswordRequirements, RegExp> = {
  digit: /\d/,
  lowercaseLetter: /[a-z]/,
  uppercaseLetter: /[A-Z]/,
  specialCharacter: /[^a-zA-Z0-9]/,
};

export const useChangePassword = () => {
  const currentUser = useSelector(userSelector)!;

  const isPasswordHelperShown = useCallback(
    (
      value: string | undefined,
      touched: boolean | undefined,
      hasValidationErrors: boolean
    ) =>
      (!touched || !hasValidationErrors) &&
      (!value || value.length < PASSWORD_MINIMAL_LENGTH),
    []
  );

  const filterMissingPasswordRequirements = useCallback(
    (password: string | undefined) => {
      if (!password) {
        return PASSWORD_REQUIREMENTS;
      }

      return PASSWORD_REQUIREMENTS.filter(
        (requirement) =>
          !PASSWORD_REQUIREMENTS_REG_EXPS[requirement].test(password)
      );
    },
    []
  );

  const getTransValidateKey = useCallback(
    (value?: string) => {
      if (!value) {
        return 'required';
      }

      if (value.length < PASSWORD_MINIMAL_LENGTH) {
        return 'passwordMinimalLength';
      }

      const missingPasswordRequirements =
        filterMissingPasswordRequirements(value);

      if (
        missingPasswordRequirements.length >
        PASSWORD_REQUIREMENTS.length - PASSWORD_MINIMAL_COMPLEXITY
      ) {
        return 'passwordComplexity';
      }

      const fields = ['username', 'firstName', 'lastName'] as const;

      for (const field of fields) {
        if (value.includes(currentUser[field])) {
          return field;
        }
      }
    },
    [currentUser, filterMissingPasswordRequirements]
  );

  return {
    getTransValidateKey,
    filterMissingPasswordRequirements,
    isPasswordHelperShown,
  };
};
