import { CardHeader } from '@fleet/shared/mui';
import { OrganizationsTypesProps } from 'routes/Organizations';
import { useHistory } from 'react-router-dom';
import { Layout } from '@fleet/shared/components/Layout';
import { TransButton } from 'i18n/trans/button';
import { OrganizationsTable } from 'routes/organizations/OrganizationsTable';
import { TransNav } from 'i18n/trans/header';
import { AddButton } from '@fleet/shared';

export const OrganizationsList = ({
  match: { path },
}: OrganizationsTypesProps) => {
  const history = useHistory();

  return (
    <Layout
      header={
        <CardHeader title={<TransNav i18nKey="organizations" />}>
          <AddButton onClick={() => history.push(`${path}/create`)}>
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
    >
      <OrganizationsTable />
    </Layout>
  );
};
