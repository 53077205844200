import { createLoadingSelector, createSelector, selector } from 'store/utils';
import { RootState } from 'store';
import { getStop, updateStop, createStop } from 'features/stop/stopActions';

export const selectStops = selector((state: RootState) => state.stops.search);
export const stopsSelector = createSelector(selectStops);

export const selectCurrentStops = selector(
  (state: RootState) => state.stops.current
);
export const currentStopSelector = createSelector(selectCurrentStops);

export const selectStopsFilter = selector(
  (state: RootState) => state.stops.filter
);
export const stopsFilterSelector = createSelector(selectStopsFilter);

export const currentStopLoadingSelector = createLoadingSelector(
  createStop,
  getStop,
  updateStop
);
