import { useEffect } from 'react';
import { OrganizationForm } from 'routes/organizations/OrganizationForm';
import {
  getOrganization,
  setCurrentOrganization,
} from 'features/organization/organizationActions';
import { useDispatch, useSelector } from 'store/utils';
import { CardHeader } from '@fleet/shared/mui';
import { currentOrganizationSelector } from 'features/organization/organizationSelectors';
import { useParams } from 'react-router-dom';
import { Layout, Loadable } from '@fleet/shared';
import { OrganizationTabs } from 'routes/organizations/OrganizationTabs';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { organizationsFormLoadingSelector } from 'features/loading/loadingSelectors';

export const OrganizationsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const organization = useSelector(currentOrganizationSelector);
  const loading = useSelector(organizationsFormLoadingSelector);

  useEffect(() => {
    if (id) {
      dispatch(getOrganization(id));
    }

    return () => {
      dispatch(setCurrentOrganization());
    };
  }, [dispatch, id]);

  return (
    <Loadable loading={loading}>
      <div>
        <Layout
          header={
            <CardHeader
              title={
                organization ? (
                  <>
                    {organization?.name}
                    {!organization.isActive && (
                      <>
                        &nbsp;
                        <TransSubtitle i18nKey="inactive" />
                      </>
                    )}
                  </>
                ) : (
                  <TransSubtitle i18nKey="newOrganization" />
                )
              }
              {...(!organization?.isActive && {
                sx: { color: 'action.disabled' },
              })}
            />
          }
        >
          <OrganizationForm organization={organization} />
        </Layout>
      </div>
      {organization && <OrganizationTabs />}
    </Loadable>
  );
};
