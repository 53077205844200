import type { FC } from 'react';
import { OrganizationsList } from 'routes/organizations/OrganizationsList';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { OrganizationsEdit } from 'routes/organizations/OrganizationsEdit';

export interface OrganizationsTypesProps
  extends RouteComponentProps<{ typeId: string }> {}

export const Organizations: FC<OrganizationsTypesProps> = ({
  match: { path },
}) => (
  <Switch>
    <Route path={path} component={OrganizationsList} exact />
    <Route
      path={`${path}/(create|edit)?/:id?/:tab?/:rowId?`}
      component={OrganizationsEdit}
      exact
    />
  </Switch>
);
