import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { getCodeLists } from 'features/codeList/codeListActions';
import { codeListsSelector } from 'features/codeList/codeListSelectors';
import { codeListsLoadingSelector } from 'features/loading/loadingSelectors';
import {
  AddButton,
  Layout,
  Loadable,
  SearchCard,
  SearchResult,
} from '@fleet/shared';
import { CardHeader } from '@fleet/shared/mui';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { CardContent, Grid } from '@mui/material';
import { StopCodeListForm } from 'routes/configuration/stopCodeLists/StopCodeListForm';

interface StopCodeListsProps {}

export const StopCodeLists: FC<StopCodeListsProps> = () => {
  const loading = useSelector(codeListsLoadingSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const stopCodeLists = useSelector(codeListsSelector);

  useEffect(() => {
    dispatch(getCodeLists('stop'));
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="stopCodeList" />}>
            <AddButton
              onClick={() =>
                history.push('/configuration/stop-code-lists/create')
              }
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/configuration/stop-code-lists/:action(create|edit)/:id?"
              component={StopCodeListForm}
              exact
            />
          </Switch>
        }
      >
        <SearchResult
          results={stopCodeLists.length}
          i18nKey="noDataAdded"
          loading={loading}
        >
          <CardContent sx={{ p: 2, flexGrow: 1 }}>
            <Grid container spacing={3}>
              {stopCodeLists.map(({ id, name, prefix }) => (
                <Grid key={id} item sm={4} md={3}>
                  <SearchCard
                    title={name}
                    list={[
                      [
                        'prefix',
                        <TransTitle i18nKey="codeListPrefix" />,
                        prefix || '-',
                      ],
                    ]}
                    {...{
                      component: Link,
                      to: `/configuration/stop-code-lists/edit/${id}`,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </SearchResult>
      </Layout>
    </Loadable>
  );
};
