import { PaginationParams } from '@fleet/shared/dto/pagination';
import { StopCode } from 'dto/stop';
import { Classifier } from '@fleet/shared/dto/classifier';

export const organizationTabs = [
  'units',
  'pointsOfSale',
  'addresses',
  'contactingOptions',
  'serviceFees',
  'bookingAccessRights',
  'stopCodes',
  'bankAccounts',
  'ticketTexts',
  'files',
] as const;

export type OrganizationTab = typeof organizationTabs[number];

export interface OrganizationLocationState {
  tab: OrganizationTab;
  entityId: string;
}

export interface Organization extends Classifier {
  brand: string;
  currency: Classifier;
  operatingTimezone: string;
  isActive: boolean;
  registrationCode: string;
  vatRegistrationCode?: string;
  retailerCodeList: Classifier;
  carrierCodeList: Classifier;
  stopCodeList: Classifier;
  carrierOptions: {
    imsConfiguration: Classifier;
  };
  roles: Array<Classifier>;
  passwordPolicies: Array<Classifier>;

  // DATA FOR TABS
  addresses: Array<OrganizationAddress>;
  bankAccounts: Array<OrganizationBankAccount>;
  bookingAccessRights: Array<BookingAccessRight>;
  contactingOptions: Array<ContactingOption>;
  files: Array<OrganizationFile>;
  pointsOfSale: Array<PointOfSale>;
  serviceFees: Array<ServiceFee>;
  stopCodes: Array<StopCode>;
  ticketTexts: Array<OrganizationTicketText>;
  units: Array<OrganizationUnit>;
}

export interface Price {
  currency: Classifier;
  maxAmount: number;
  minAmount: number;
}

export interface ServiceFee {
  id: string;
  pointOfSaleType: Classifier;
  prices: Array<Price>;
  type: Classifier;
  validity: { from: string; to: string };
  value: number;
  vatRate: number;
}
export interface ServiceFeePayload
  extends Omit<ServiceFee, 'pointOfSaleType' | 'type' | 'prices' | 'validity'> {
  pointOfSaleTypeId: string;
  typeId: string;
  validity: { from: string; to: string };
  prices?: Array<{
    currencyId: string;
    maxAmount: number;
    minAmount: number;
  }>;
}
export interface OrganizationUnit extends Classifier {
  code: string;
  isActive: boolean;
  pointsOfSale: Array<PointOfSale>;
}

export interface UnitPayload extends Omit<OrganizationUnit, 'country'> {
  countryId: string;
}

export interface BookingAccessRight {
  id: string;
  organization: Classifier;
  retailer: Classifier;
  hasPermissionToView: boolean;
  hasPermissionToCancel: boolean;
  validity: { from: string; to: string };
}

export interface BookingAccessRightPayload
  extends Omit<BookingAccessRight, 'organization' | 'retailer' | 'validity'> {
  organizationId: string;
  validity: { from: string; to: string };
  retailerId: string;
}

export interface PointOfSale extends Classifier {
  isActive: boolean;
  toolType: Classifier;
  type: Classifier;
  organizationUnitId: string;
}

export interface PointsOfSalePayload
  extends Omit<PointOfSale, 'type' | 'toolType'> {
  typeId: string;
  toolType?: string | null;
  organizationUnitId: string;
}

export interface OrganizationAddress {
  city: string;
  country: Classifier;
  county: string;
  id: string;
  street: string;
  type: Classifier;
  zipCode: string;
  isActive: boolean;
  organizationUnitId: string;
}

export interface OrganizationAddressPayload
  extends Omit<OrganizationAddress, 'type' | 'country'> {
  typeId: string;
  countryId: string;
}

export interface ContactingOption {
  id: string;
  value: string;
  purposeType: Classifier;
  optionType: Classifier;
  organizationUnitId: string;
}

export interface ContactingOptionPayload
  extends Omit<OrganizationAddress, 'purposeType' | 'optionType'> {
  purposeTypeId: string;
  optionTypeId: string;
}

export interface OrganizationSearchFilter extends PaginationParams {
  Name: string;
  AssignedRoles?: Array<string>;
  RegistrationCode: string;
  IsActive?: boolean;
}

export interface Application extends Classifier {
  clientId: string;
  clientSecret: string;
  isImmutable: Boolean;
  applicationUrl: string;
  organiztion: Classifier;
  pointOfSale: Classifier;
  unit: Classifier;
}

export interface OrganizationBankAccount extends Classifier {
  type: Classifier;
  clearingNumber: string;
  accountNumber: string;
  iban: string;
  giroNumber: string;
  organizationUnitId: string;
}

export interface BankAccountPayload
  extends Omit<OrganizationBankAccount, 'type'> {
  typeId: string;
}

export interface OrganizationTicketText {
  id: string;
  text: string;
  language: Classifier;
  validityPeriod: { from: string; to: string };
  organizationUnitId: string;
}

export interface TicketTextPayload
  extends Omit<OrganizationTicketText, 'language'> {
  languageId: string;
}

export interface OrganizationFile extends Classifier {
  fileId: string;
  contentType: string;
  typeId: string;
}
