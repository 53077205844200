import { createAsyncThunk } from 'store/utils';
import { createAction } from '@reduxjs/toolkit';
import { User, UserPayload, UserRoles, UserSearchFilter } from 'dto/user';
import qs from 'qs';
import { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';

export const setUsersFilter =
  createAction<Partial<UserSearchFilter>>('setUsersFilter');

export const clearUsers = createAction('user/clearUsers');

export const getUsers = createAsyncThunk<
  Pagination<User>,
  Partial<UserSearchFilter> | undefined
>('user/getUsers', async (values, { dispatch, getState }) => {
  values && dispatch(setUsersFilter(values));
  const { filter } = getState().user;
  return (
    await api.get(
      `/users${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data;
});

export const getRoles = createAsyncThunk<UserRoles>(
  'user/getRoles',
  async () => (await api.get('/roles')).data.items
);

export const setUser = createAction<User | undefined>('user/setUser');

export const getUser = createAsyncThunk<User, string>(
  'user/getUser',
  async (id, store) => {
    const user = (await api.get(`/users/${id}`)).data;
    store.dispatch(setUser(user));
    return user;
  }
);

export const updateOrCreateUser = createAsyncThunk<User, UserPayload>(
  'user/updateUser',
  async ({ id, ...payload }) =>
    (await (id ? api.put : api.post)(`/users${id ? `/${id}` : ''}`, payload))
      .data
);

export const setUserAvailablePasswordPolicies = createAction<Classifier[]>(
  'user/setUserAvailablePasswordPolicies'
);

export const getUserAvailablePasswordPolicies = createAsyncThunk<
  Classifier[],
  string
>(
  'user/getUserAvailablePasswordPolicies',
  async (id) =>
    (
      await api.get<Pagination<Classifier>>(
        `/users/${id}/available-password-policies`
      )
    ).data.items
);

export const assignUserOrganization = createAsyncThunk<
  void,
  {
    userId: string;
    organizationId: string;
  }
>('user/assignOrganization', async ({ userId, organizationId }) => {
  await api.post(`/users/${userId}/organizations`, { organizationId });
});
export const assignUserOrganizationUnit = createAsyncThunk<
  void,
  {
    userId: string;
    unitId: string;
  }
>('user/assignOrganizationUnit', async ({ userId, unitId }) => {
  await api.post(`/users/${userId}/units`, { unitId });
});
export const deleteUserOrganizationUnit = createAsyncThunk<
  void,
  { userId: string; unitId: string }
>('user/deleterOrganizationUnit', async ({ userId, unitId }) => {
  await api.delete(`/users/${userId}/units/${unitId}`);
});

export const assignUserOrganizationPointOfSale = createAsyncThunk<
  void,
  {
    userId: string;
    pointOfSaleId: string;
  }
>('user/assignOrganizationPointOfSale', async ({ userId, pointOfSaleId }) => {
  await api.post(`/users/${userId}/point-of-sales`, {
    pointOfSaleId,
  });
});
export const deleteUserOrganizationPointOfSale = createAsyncThunk<
  void,
  { userId: string; pointOfSaleId: string }
>('user/deleteOrganizationPointOfSale', async ({ userId, pointOfSaleId }) => {
  await api.delete(`/users/${userId}/point-of-sales/${pointOfSaleId}`);
});

export const deleteUserOrganization = createAsyncThunk<
  void,
  { userId: string; organizationId: string }
>('user/deleteOrganization', async ({ userId, organizationId }) => {
  await api.delete(`/users/${userId}/organizations/${organizationId}`);
});

export const assignUserRole = createAsyncThunk<
  void,
  { userId: string; roleId: string }
>('user/assignRole', async ({ userId, roleId }) => {
  await api.post(`/users/${userId}/roles`, { roleId });
});

export const deleteUserRole = createAsyncThunk<
  void,
  { userId: string; roleId: string }
>('user/deleteRole', async ({ userId, roleId }) => {
  await api.delete(`/users/${userId}/roles/${roleId}`);
});
