import { TableInstance } from 'react-table';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { OrganizationLocationState } from 'dto/organization';

export const useHighLightAuditRow = <D extends { id: string }>(
  table: TableInstance<D>,
  data: Array<D>
) => {
  const location = useLocation<OrganizationLocationState>();

  useEffect(() => {
    const entityId = location.state?.entityId;
    if (entityId) {
      const rowId = data.findIndex((row) => row.id === entityId);
      rowId >= 0 && table.toggleRowActive(`${rowId}`, true);
    }
  }, [data, location.state?.entityId, table]);
};
