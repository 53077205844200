import { FC, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Divider, Stack } from '@mui/material';
import { Row } from 'react-table';
import { TransTableHead } from 'i18n/trans/table';
import { UserFormOrganizationsSubRowTable } from 'routes/users/UserFormOrganizationsSubRowTable';
import { PointOfSale, OrganizationUnit } from 'dto/organization';
import { useDispatch, useSelector } from 'store/utils';
import { getAvailableUnitsAndPointOfSales } from 'features/organization/organizationActions';
import { useCallback } from 'react';
import {
  assignUserOrganizationPointOfSale,
  assignUserOrganizationUnit,
  deleteUserOrganizationPointOfSale,
  deleteUserOrganizationUnit,
  getUser,
} from 'features/user/userActions';
import { userSelector } from 'features/user/userSelectors';
import { Loader } from '@fleet/shared';
import { UserOrganization } from 'dto/user';

interface UserFormOrganizationsSubRowProps {
  row: Row<UserOrganization>;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1),
      position: 'relative',
      minHeight: 100,
    },
  }),
  {
    name: 'UserFormOrganizationsSubRow',
  }
);

export const UserFormOrganizationsSubRow: FC<UserFormOrganizationsSubRowProps> =
  ({ row }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentOrganizationId = useMemo(() => row.original.id, [row]);
    const currentUser = useSelector(userSelector);
    const [options, setOptions] = useState<{
      units: Array<OrganizationUnit>;
      pointsOfSale: Array<PointOfSale>;
      loading: boolean;
    }>({ units: [], pointsOfSale: [], loading: true });

    const { id: userId } = currentUser!;
    const actions = useMemo(
      () => ({
        units: {
          assign: (unitId: string) =>
            dispatch(assignUserOrganizationUnit({ userId, unitId })),
          unAssign: (unitId: string) =>
            dispatch(deleteUserOrganizationUnit({ userId, unitId })),
        },
        pointsOfSale: {
          assign: (pointOfSaleId: string) =>
            dispatch(
              assignUserOrganizationPointOfSale({ userId, pointOfSaleId })
            ),
          unAssign: (pointOfSaleId: string) =>
            dispatch(
              deleteUserOrganizationPointOfSale({ userId, pointOfSaleId })
            ),
        },
      }),
      [dispatch, userId]
    );

    const getUpdateHandler = useCallback(async () => {
      await dispatch(getUser(currentUser!.id));
    }, [dispatch, currentUser]);

    useEffect(() => {
      (async () => {
        const options = await dispatch(
          getAvailableUnitsAndPointOfSales(currentOrganizationId)
        ).unwrap();
        setOptions({ ...options, loading: false });
      })();

      return () => setOptions({ units: [], pointsOfSale: [], loading: true });
    }, [dispatch, currentOrganizationId]);

    return (
      <Paper elevation={0} className={classes.root}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          {options.loading ? (
            <Loader active size="container" />
          ) : (
            <>
              <UserFormOrganizationsSubRowTable
                header={<TransTableHead i18nKey="unit" />}
                selected={row.original.units}
                data={options.units}
                onUpdate={getUpdateHandler}
                onAssign={actions.units.assign}
                onUnAssign={actions.units.unAssign}
              />
              <UserFormOrganizationsSubRowTable
                header={<TransTableHead i18nKey="salesPoint" />}
                selected={row.original.pointsOfSale}
                data={options.pointsOfSale}
                onUpdate={getUpdateHandler}
                onAssign={actions.pointsOfSale.assign}
                onUnAssign={actions.pointsOfSale.unAssign}
              />
            </>
          )}
        </Stack>
      </Paper>
    );
  };
