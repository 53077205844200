import { FC, useCallback, useMemo } from 'react';
import { Button, CardContent, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CheckboxGroupField,
  ConfirmDeleteModal,
  FormProvider,
  formSubmit,
  makeClassificationOptions,
  TimeZoneField,
  useForm,
  useModal,
} from '@fleet/shared';
import { ConditionField, SelectField, TextField } from '@fleet/shared/form';
import { Organization } from 'dto/organization';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import {
  activateOrganization,
  createOrganization,
  deactivateOrganization,
  deleteOrganization,
  getOrganizationsList,
  updateOrganization,
} from 'features/organization/organizationActions';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { Icon } from '@fleet/shared/mui';
import { TransModal } from 'i18n/trans/modal';
import { classificationsSelector } from 'features/classification/classificationSelectors';

const useStyles = makeStyles(() => ({
  detailsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
}));

interface OrganizationFormProps {
  organization?: Organization;
}

interface OrganizationFormValues extends Partial<Omit<Organization, 'roles'>> {
  roles: Array<string>;
  currencyId: string;
  configurationId?: string;
  retailerCodeListId: string;
  carrierCodeListId: string;
  stopCodeListId: string;
}

export const OrganizationForm: FC<OrganizationFormProps> = ({
  organization,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const alert = useAlert();
  const currencies = useClassificationOptions(ClassificationGroup.CURRENCY);
  const roles = useClassificationOptions(ClassificationGroup.ORGANIZATION_ROLE);
  const organizationCodeList = useSelector(classificationsSelector)[
    ClassificationGroup.ORGANIZATION_CODE_LIST
  ];
  const uniqueOrganizationCodeListOptions = makeClassificationOptions(
    (organizationCodeList || []).filter(({ hasUniqueCodes }) => hasUniqueCodes)
  );
  const stopCodeList = useClassificationOptions(
    ClassificationGroup.STOP_CODE_LIST
  );

  const imsList = useClassificationOptions(ClassificationGroup.IMS);
  const { open: isOpen, onOpen, onClose } = useModal();

  const initialValues = useMemo(
    () =>
      organization && {
        id: organization.id,
        name: organization.name,
        brand: organization.brand,
        roles: organization.roles.map((role) => role.id),
        currencyId: organization.currency.id,
        registrationCode: organization.registrationCode,
        vatRegistrationCode: organization.vatRegistrationCode,
        configurationId: organization.carrierOptions
          ? organization.carrierOptions.imsConfiguration.id
          : '',
        retailerCodeListId: organization.retailerCodeList.id,
        carrierCodeListId: organization.carrierCodeList.id,
        stopCodeListId: organization.stopCodeList.id,
        operatingTimezone: organization.operatingTimezone,
      },
    [organization]
  );

  const onSubmit = useCallback(
    (data) =>
      formSubmit(async () => {
        const temp = {
          ...data,
          carrierOptions:
            data.configurationId && data.configurationId != ''
              ? {
                  imsConfigurationId: data.configurationId,
                }
              : null,
        };
        if (id) {
          await dispatch(updateOrganization(temp));
        } else {
          const { id } = await dispatch(createOrganization(temp)).unwrap();
          history.push(`/organizations/edit/${id}`);
          alert.success(<TransAlert i18nKey="organizationCreated" />);
        }

        dispatch(getOrganizationsList());
      }),
    [dispatch, history, id, alert]
  );
  const { form, handleSubmit, submitting, pristine } =
    useForm<OrganizationFormValues>({
      initialValues,
      onSubmit,
      subscription: { submitting: true, pristine: true },
    });

  const { reset } = form;

  const handleActivation = useCallback(async () => {
    dispatch(activateOrganization(id));
  }, [dispatch, id]);

  const handleDeactivation = useCallback(async () => {
    dispatch(deactivateOrganization(id));
  }, [dispatch, id]);

  const handleDelete = useCallback(async () => {
    dispatch(deleteOrganization(id))
      .unwrap()
      .then(() => alert.success(<TransAlert i18nKey="organizationDeleted" />));

    history.push('/organizations');
  }, [alert, dispatch, history, id]);

  const handleReset = useCallback(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const hasLegalAddress = useMemo(
    () =>
      organization?.addresses
        .map(({ type: { id } }) => id)
        .includes('ADDRESS_TYPE.LEGAL'),
    [organization?.addresses]
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <CardContent sx={{ p: '16px 24px' }}>
          <div className={classes.detailsHeader}>
            <Typography variant="subtitle" fontWeight="700" paragraph>
              <TransSubtitle i18nKey="details" />
            </Typography>
            {organization?.id && (
              <Stack direction="row">
                {organization?.isActive ? (
                  <Button
                    onClick={handleDeactivation}
                    startIcon={<Icon name="error-circle" />}
                    variant="text"
                    sx={{ fontSize: '12px' }}
                  >
                    <TransButton i18nKey="deactivate" />
                  </Button>
                ) : (
                  <Button
                    disabled={!hasLegalAddress}
                    onClick={handleActivation}
                    startIcon={<Icon name="check" />}
                    variant="text"
                    sx={{ fontSize: '12px' }}
                  >
                    <TransButton i18nKey="activate" />
                  </Button>
                )}
                <>
                  <Button
                    onClick={onOpen}
                    startIcon={<Icon name="delete" />}
                    variant="text"
                    sx={{ fontSize: '12px', p: '6px 0 6px 16px' }}
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                  <ConfirmDeleteModal
                    handleDelete={handleDelete}
                    title={<TransModal i18nKey="deleteOrganization" />}
                    description={
                      <TransModal
                        i18nKey="organizationDeletionDescription"
                        values={{ name: organization.name }}
                      />
                    }
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                </>
              </Stack>
            )}
          </div>
          <Grid
            container
            sx={{ alignItems: 'baseline', pb: 3 }}
            spacing={3}
            columns={5}
          >
            <Grid item xs>
              <TextField
                name="name"
                label={<TransField i18nKey="name" />}
                required
              />
            </Grid>
            <Grid item xs>
              <TimeZoneField
                name="operatingTimezone"
                label={<TransField i18nKey="timeZone" />}
                required
              />
            </Grid>
            <Grid item xs>
              <TextField name="brand" label={<TransField i18nKey="brand" />} />
            </Grid>
            <Grid item xs>
              <CheckboxGroupField
                required
                options={roles}
                name="roles"
                label={<TransField i18nKey="roles" />}
                disabled={!!id}
                inline
              />
            </Grid>
            <Grid item xs>
              <SelectField
                options={currencies}
                name="currencyId"
                label={<TransField i18nKey="currency" />}
                required
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ alignItems: 'baseline', pb: 2 }}
            spacing={3}
            columns={5}
          >
            <Grid item xs>
              <TextField
                name="registrationCode"
                label={<TransField i18nKey="registrationCode" />}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="vatRegistrationCode"
                label={<TransField i18nKey="vatRegistrationCode" />}
              />
            </Grid>
            <ConditionField
              when="roles"
              is={(x: string | string[]) =>
                x.includes('ORGANIZATION_ROLE.CARRIER')
              }
            >
              <Grid item xs={1}>
                <SelectField
                  options={imsList}
                  name="configurationId"
                  label={<TransField i18nKey="ims" />}
                  disabled={!!id}
                  required
                />
              </Grid>
            </ConditionField>
            <Grid item xs={1}>
              <SelectField
                options={uniqueOrganizationCodeListOptions}
                name="carrierCodeListId"
                label={<TransField i18nKey="carrierCodeList" />}
                disabled={!!id}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                options={uniqueOrganizationCodeListOptions}
                name="retailerCodeListId"
                label={<TransField i18nKey="retailerCodeList" />}
                disabled={!!id}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                options={stopCodeList}
                name="stopCodeListId"
                label={<TransField i18nKey="stopCodeList" />}
                disabled={!!id}
                required
              />
            </Grid>
          </Grid>

          <Stack
            direction="row"
            sx={{ alignItems: 'center', justifyContent: 'end' }}
            spacing={1}
          >
            {organization?.id ? (
              <Button disabled={pristine} variant="text" onClick={handleReset}>
                <TransButton i18nKey="resetChanges" />
              </Button>
            ) : (
              <Button variant="text" component={Link} to="/organizations">
                <TransButton i18nKey="cancel" />
              </Button>
            )}
            <Button
              disabled={submitting}
              startIcon={<Icon name="plus24" />}
              type="submit"
              variant="contained"
            >
              {organization?.id ? (
                <TransButton i18nKey="save" />
              ) : (
                <TransButton i18nKey="create" />
              )}
            </Button>
          </Stack>
        </CardContent>
      </form>
    </FormProvider>
  );
};
