import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import {
  FormProvider,
  useForm,
  Checkbox,
  Table,
  TableColumns,
  useFormTable,
  useRowEditActions,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
} from '@fleet/shared';
import { useRowSelect, useFilters } from 'react-table';
import { OrganizationAddress } from 'dto/organization';
import { TransTableHead } from 'i18n/trans/table';
import { Icon } from '@fleet/shared/mui';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getOrganization,
  removeAddresses,
  updateOrCreateAddress,
} from 'features/organization/organizationActions';
import { useFormTableControls } from '@fleet/shared/hooks';
import { currentOrganizationSelector } from 'features/organization/organizationSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useHighLightAuditRow } from 'routes/organizations/hooks/useHighLightAuditRow';

interface AddressesProps {
  data: Array<OrganizationAddress>;
}

export const Addresses: FC<AddressesProps> = ({ data }) => {
  const dispatch = useDispatch();
  const addressTypes = useClassificationOptions(
    ClassificationGroup.ADDRESS_TYPE
  );
  const countries = useClassificationOptions(ClassificationGroup.COUNTRY);
  const organization = useSelector(currentOrganizationSelector);

  const unitOptions = useMemo(() => {
    if (organization) {
      const options = organization.units.map(({ id, name }) => ({
        value: id,
        label: name,
      }));

      return [...options, { value: null, label: '—' }];
    }
  }, [organization]);

  const columns: TableColumns<OrganizationAddress> = useMemo(
    () => [
      {
        id: 'type.id',
        accessor: ({ type }) => type?.id,
        Header: <TransTableHead i18nKey="type" />,
        type: 'select',
        editableProps: {
          options: addressTypes,
        },
      },
      {
        accessor: 'organizationUnitId',
        Header: <TransTableHead i18nKey="unit" />,
        type: 'select',
        editableProps: {
          options: unitOptions,
          required: false,
        },
      },
      {
        accessor: 'street',
        Header: <TransTableHead i18nKey="street" />,
      },
      {
        accessor: 'zipCode',
        Header: <TransTableHead i18nKey="zipCode" />,
        editableProps: { required: false },
      },
      {
        id: 'country.id',
        accessor: ({ country }) => country?.id,
        Header: <TransTableHead i18nKey="country" />,
        type: 'select',
        editableProps: { options: countries },
      },
      {
        accessor: 'county',
        Header: <TransTableHead i18nKey="county" />,
        editableProps: { required: false },
      },
      {
        accessor: 'city',
        Header: <TransTableHead i18nKey="city" />,
        editableProps: { required: false },
      },
      {
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="isActive" />,
        type: 'checkbox',
        conditions: {
          disabled: {
            when: 'type.id',
            is: (val, data) => {
              if (val !== 'ADDRESS_TYPE.LEGAL') return false;
              if (data.row.original.isActive) return false;

              return data.rows.some(
                ({ original }) =>
                  original.type?.id === 'ADDRESS_TYPE.LEGAL' &&
                  original.isActive
              );
            },
          },
        },
        editableProps: {
          required: false,
        },
      },
    ],
    [addressTypes, countries, unitOptions]
  );
  const { form } = useForm<{ rows: Array<OrganizationAddress> }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable<OrganizationAddress>(
    {
      data,
      columns,
      form,
      initialState: {
        hiddenColumns: ['id'],
      },
      onRowUpdate: async ({ type, country, ...payload }) => {
        await dispatch(
          updateOrCreateAddress({
            ...payload,
            countryId: country.id,
            typeId: type.id,
          })
        ).unwrap();
        await dispatch(getOrganization());
      },
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowActive
  );
  useHighLightAuditRow(table, data);
  const handleActiveFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isActive', e.target.checked),
    [table]
  );

  const onRowsRemove = useCallback(
    async (payload: Array<OrganizationAddress>) => {
      await dispatch(removeAddresses(payload)).unwrap();
      await dispatch(getOrganization());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Checkbox
            size="small"
            sx={{ flex: 1 }}
            label={<TransField i18nKey="activeAddresses" />}
            onChange={handleActiveFilterToggle}
            inline
          />
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
            disabled={!Object.keys(table.state.selectedRowIds).length}
            color="error"
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table table={table} />
        {Boolean(!table.rows.length) && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ m: 2, fontSize: 14 }}
          >
            <TransSubtitle i18nKey="noAddressesFound" />
          </Typography>
        )}
      </Box>
    </FormProvider>
  );
};
