import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  CardHeader,
  Drawer,
  FormProvider,
  Modal,
  useForm,
} from '@fleet/shared';
import { TextField } from '@fleet/shared/form';
import {
  Button,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import { useHistory, useParams } from 'react-router-dom';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import {
  createCodeList,
  deleteCodeList,
  getCodeListById,
  getCodeLists,
  setCodeList,
  updateCodeList,
} from 'features/codeList/codeListActions';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { useModal } from '@fleet/shared/hooks';
import { codeListItemSelector } from 'features/codeList/codeListSelectors';
import { CodeList } from 'dto/codeList';
import { TransModal } from 'i18n/trans/modal';

const useStyles = makeStyles(
  (theme) => ({
    formFields: {
      '&& > .MuiFormControl-root': {
        '&:not($formSwitch)': {
          marginBottom: 16,
        },

        '& > label': {
          color: theme.palette.text.primary,
          fontSize: 14,
          flexGrow: 1,
        },
      },
    },
    formSwitch: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
      '& .Icon-root': {
        pointerEvents: 'all',
      },
    },
  }),
  {
    name: 'StopCodeListForm',
  }
);

interface StopCodeListFormProps {}

export const StopCodeListForm: FC<StopCodeListFormProps> = () => {
  const dispatch = useDispatch();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const currentCodeItem = useSelector(codeListItemSelector);
  const classes = useStyles();
  const { open: isOpen, onOpen, onClose } = useModal();
  const history = useHistory();
  const alert = useAlert();

  useEffect(() => {
    dispatch(setCodeList());

    if (action === 'edit' && id) {
      dispatch(getCodeListById({ id, type: 'stop' }));
    }

    return () => {
      dispatch(setCodeList());
    };
  }, [action, dispatch, id]);

  const closeDrawer = useCallback(() => {
    history.replace('/configuration/stop-code-lists');
  }, [history]);

  const onSubmit = useCallback(
    async (values: CodeList) => {
      await dispatch(
        (values.id ? updateCodeList : createCodeList)(values)
      ).unwrap();
      alert.success(
        <TransAlert
          i18nKey={values.id ? 'stopCodeListSaved' : 'stopCodeListCreated'}
        />
      );
      closeDrawer();

      await dispatch(getCodeLists('stop'));
    },
    [alert, closeDrawer, dispatch]
  );

  const initialValues = useMemo(
    () => ({ type: 'stop', hasUniqueCodes: false, ...currentCodeItem }),
    [currentCodeItem]
  );

  const { form, handleSubmit, submitting } = useForm<CodeList>({
    initialValues,
    onSubmit,
    subscription: { submitting: true },
  });

  const handleDelete = useCallback(async () => {
    await dispatch(
      deleteCodeList({ id: currentCodeItem!.id, type: 'stop' })
    ).unwrap();
    await dispatch(getCodeLists('stop')).unwrap();

    alert.success(<TransAlert i18nKey="stopCodeListDeleted" />);
    closeDrawer();
  }, [alert, closeDrawer, currentCodeItem, dispatch]);

  return (
    <Drawer elevation={0} anchor="right" open onClose={closeDrawer}>
      <FormProvider {...form}>
        <Stack
          sx={{
            width: '400px',
            height: '100vh',
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <CardHeader
            isLight
            title={
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography variant="subtitle">
                  <TransTitle i18nKey="stopCodeList" />
                </Typography>
                {id && (
                  <>
                    <Button
                      sx={{ paddingLeft: 0 }}
                      startIcon={<Icon name="trash" size={16} />}
                      size="small"
                      onClick={onOpen}
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                    <Modal
                      open={isOpen}
                      onClose={onClose}
                      title={<TransModal i18nKey="deleteCodeList" />}
                      message={`Are you sure you want to delete stop code list '${currentCodeItem?.name}'? You can't undo this`}
                      actionButton={
                        <Button
                          type="submit"
                          startIcon={<Icon name="trash" />}
                          variant="contained"
                          color="error"
                          disabled={submitting}
                          onClick={handleDelete}
                        >
                          <TransButton i18nKey="delete" />
                        </Button>
                      }
                    />
                  </>
                )}
              </Stack>
            }
            action={
              <IconButton aria-label="close" onClick={closeDrawer}>
                <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            }
          />
          <CardContent
            sx={{ px: 3, py: 2, flex: 1, overflowy: 'scroll' }}
            className={classes.formFields}
          >
            <TextField
              label={<TransField i18nKey="name" />}
              name="name"
              required
            />
            <TextField label={<TransField i18nKey="prefix" />} name="prefix" />
          </CardContent>
          <CardActions
            sx={{ padding: 3, justifyContent: 'flex-end', boxShadow: 2 }}
          >
            <Button variant="text" color="primary" onClick={closeDrawer}>
              <TransButton i18nKey="cancel" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<Icon name={id ? 'check' : 'plus'} />}
            >
              <TransButton i18nKey={id ? 'save' : 'add'} />
            </Button>
          </CardActions>
        </Stack>
      </FormProvider>
    </Drawer>
  );
};
