import { FC, useCallback, useMemo } from 'react';
import {
  FormProvider,
  getStartOfDate,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
  useRowEditActions,
} from '@fleet/shared';
import { useRowSelect } from 'react-table';
import { BookingAccessRight } from 'dto/organization';
import { TransTableHead } from 'i18n/trans/table';
import { Icon } from '@fleet/shared/mui';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { useDispatch } from 'store/utils';
import {
  getOrganization,
  removeBookingAccessRights,
  updateOrCreateBookingAccessRight,
} from 'features/organization/organizationActions';
import { formatDate, parseISODateString } from '@fleet/shared/utils/date';
import { useFormTableControls } from '@fleet/shared/hooks';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useHighLightAuditRow } from 'routes/organizations/hooks/useHighLightAuditRow';
import _unionBy from 'lodash/unionBy';

interface BookingAccessRightsProps {
  data: Array<BookingAccessRight>;
}

export const BookingAccessRights: FC<BookingAccessRightsProps> = ({ data }) => {
  const dispatch = useDispatch();
  const retailerOptions = useClassificationOptions(
    ClassificationGroup.RETAILER
  );

  const inputRetailers = useMemo(
    () =>
      data.map((bookingAccessRight) => ({
        label: bookingAccessRight.retailer.name,
        value: bookingAccessRight.retailer.id,
      })),
    [data]
  );
  const classificationAndInputRetailers = useMemo(
    () => _unionBy(retailerOptions, inputRetailers, 'value'),
    [retailerOptions, inputRetailers]
  );

  const columns: TableColumns<BookingAccessRight> = useMemo(
    () => [
      {
        id: 'retailer.id',
        accessor: ({ retailer }) => retailer?.id,
        Header: <TransTableHead i18nKey="retailer" />,
        type: 'select',
        editableProps: { options: classificationAndInputRetailers },
      },
      {
        id: 'validity.from',
        accessor: ({ validity }) => formatDate(validity?.from),
        Header: <TransTableHead i18nKey="validFrom" />,
        type: 'date',
        editableProps: {
          minDate: () => getStartOfDate(new Date()),
        },
      },
      {
        id: 'validity.to',
        accessor: ({ validity }) => formatDate(validity?.to),
        Header: <TransTableHead i18nKey="validTo" />,
        type: 'date',
        conditions: {
          disabled: {
            when: 'validity.from',
            is: '',
          },
        },
        editableProps: {
          // @ts-ignore
          minDate: (values) => parseISODateString(values['validity.from']),
        },
      },
      {
        accessor: 'hasPermissionToView',
        Header: <TransTableHead i18nKey="viewPermission" />,
        // non-editable
        Cell: () => <Icon name="check" color="success" />,
      },
      {
        accessor: 'hasPermissionToCancel',
        Header: <TransTableHead i18nKey="cancelPermission" />,
        type: 'checkbox',
        editableProps: { required: false, defaultValue: true },
      },
    ],
    [classificationAndInputRetailers]
  );

  const { form } = useForm<{ rows: Array<BookingAccessRight> }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable<BookingAccessRight>(
    {
      data,
      columns,
      form,
      initialState: {
        hiddenColumns: ['id'],
      },
      onRowUpdate: async ({
        id,
        retailer,
        validity,
        hasPermissionToCancel = true,
      }) => {
        await dispatch(
          updateOrCreateBookingAccessRight({
            id,
            validity: {
              to: validity.to,
              from: validity.from,
            },
            retailerId: retailer.id,
            hasPermissionToCancel,
          })
        ).unwrap();
        await dispatch(getOrganization());
      },
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowActive
  );
  useHighLightAuditRow(table, data);
  const onRowsRemove = useCallback(
    async (payload: Array<BookingAccessRight>) => {
      await dispatch(removeBookingAccessRights(payload)).unwrap();
      await dispatch(getOrganization());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });
  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
            disabled={!Object.keys(table.state.selectedRowIds).length}
            color="error"
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table table={table} />
        {Boolean(!table.rows.length) && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ m: 2, fontSize: 14 }}
          >
            <TransSubtitle i18nKey="noBookingAccessRightsFound" />
          </Typography>
        )}
      </Box>
    </FormProvider>
  );
};
