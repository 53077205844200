import { createAsyncThunk } from 'store/utils';
import { CodeList } from 'dto/codeList';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { api } from '@fleet/shared';

export const getCodeLists = createAsyncThunk<
  Array<CodeList>,
  'organization' | 'stop'
>(
  'codeList/getCodeLists',
  async (type) =>
    (
      await api.get(
        `/code-lists/${type}?${qs.stringify({
          limit: 1000,
          offset: 0,
        })}`
      )
    ).data.items
);

export const setCodeList = createAction<CodeList | undefined>(
  'codeList/setCodeList'
);

export const getCodeListById = createAsyncThunk<
  CodeList,
  { id: string; type: 'organization' | 'stop' }
>('codeList/getCodeListById', async ({ id, type }, store) => {
  const codeList = (await api.get(`/code-lists/${type}/${id}`)).data;
  store.dispatch(setCodeList(codeList));
  return codeList;
});

export const createCodeList = createAsyncThunk<CodeList, CodeList>(
  'codeList/createCodeList',
  async ({ type, ...payload }) =>
    (await api.post(`/code-lists/${type}`, payload)).data
);

export const updateCodeList = createAsyncThunk<CodeList, CodeList>(
  'codeList/updateCodeList',
  async ({ id, type, ...payload }) =>
    (await api.put(`/code-lists/${type}/${id}`, payload)).data
);

export const deleteCodeList = createAsyncThunk<
  void,
  { id: string; type: 'organization' | 'stop' }
>('codeList/deleteCodeList', async ({ id, type }) => {
  await api.delete(`/code-lists/${type}/${id}`);
});
