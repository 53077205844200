import { api } from '@fleet/shared';
import { Pagination } from '@fleet/shared/dto/pagination';
import { createAction } from '@reduxjs/toolkit';
import {
  BookingFee,
  BookingFeeFilter,
  BookingFeeFormValues,
} from 'dto/bookingFee';
import { createAsyncThunk } from 'store/utils';
import qs from 'qs';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';

export const setBookingFeesFilter = createAction<Partial<BookingFeeFilter>>(
  'bookingFee/setBookingFeesFilter'
);

export const clearBookingFees = createAction('bookingFee/clearBookingFees');

export const getBookingFees = createAsyncThunk<
  Pagination<BookingFee>,
  Partial<BookingFeeFilter> | undefined
>('bookingFee/getBookingFees', async (values, { getState, dispatch }) => {
  if (values) {
    dispatch(setBookingFeesFilter(values));
  }

  const {
    bookingFee: { filter },
  } = getState();

  const { ownerId, ...rest } = filter;

  return (
    await api.get<Pagination<BookingFee>>(
      `/owners/${ownerId}/configuration/booking-fee${qs.stringify(rest, {
        addQueryPrefix: true,
        skipNulls: true,
        allowDots: true,
      })}`
    )
  ).data;
});

export const setBookingFee = createAction<BookingFee | undefined>(
  'bookingFee/setBookingFee'
);

export const getBookingFee = createAsyncThunk<BookingFee, string | number>(
  'bookingFee/getBookingFee',
  async (id, { getState, dispatch }) => {
    const { data } = await api.get(
      `/owners/${selectCurrentBusinessEntityId(
        getState()
      )}/configuration/booking-fee/${id}`
    );
    dispatch(setBookingFee(data));

    return data;
  }
);

export const createBookingFee = createAsyncThunk<
  BookingFee,
  Omit<BookingFeeFormValues, 'id'>
>(
  'bookingFee/createBookingFee',
  async (payload, { getState }) =>
    (
      await api.post<BookingFee>(
        `/owners/${selectCurrentBusinessEntityId(
          getState()
        )}/configuration/booking-fee`,
        payload
      )
    ).data
);

export const updateBookingFee = createAsyncThunk<
  BookingFee,
  BookingFeeFormValues
>(
  'bookingFee/updateBookingFee',
  async ({ id, ...payload }, { getState, dispatch }) => {
    const { data } = await api.put<BookingFee>(
      `/owners/${selectCurrentBusinessEntityId(
        getState()
      )}/configuration/booking-fee/${id}`,
      payload
    );
    dispatch(setBookingFee(data));

    return data;
  }
);

export const deleteBookingFees = createAsyncThunk<void, BookingFee['id'][]>(
  'bookingFee/deleteBookingFees',
  async (ids, { getState, dispatch }) => {
    await api.post(
      `/owners/${selectCurrentBusinessEntityId(
        getState()
      )}/configuration/booking-fee/bulk-delete`,
      { ids }
    );
    await dispatch(getBookingFees());
  }
);
