import { FormProvider, Icon, formSubmit, useForm } from '@fleet/shared';
import { Button, Grid, Stack } from '@mui/material';
import { TextField, SelectField, RadioGroupField } from '@fleet/shared/form';
import { FormControl } from '@fleet/shared/mui/FormControl';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { useCallback } from 'react';
import { SearchForm } from '@fleet/shared';
import { StopSearchFilter } from 'dto/stop';
import { stopsFilterSelector } from 'features/stop/stopSelectors';
import { getStopsList } from 'features/stop/stopActions';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { ClassificationGroup } from 'dto/classification';

export const StopsSearchForm = () => {
  const dispatch = useDispatch();
  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);
  const filter = useSelector(stopsFilterSelector);

  const onSubmit = useCallback(
    (values: StopSearchFilter) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getStopsList({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const { form, handleSubmit, dirty } = useForm<StopSearchFilter>({
    initialValues: filter,
    onSubmit,
  });

  const handleFilterReset = useCallback(() => {
    form.restart({});
    dirty && form.submit();
  }, [dirty, form]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <TextField name="name" label={<TransField i18nKey="name" />} />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                showEmptyOption
                name="countryId"
                label={<TransField i18nKey="country" />}
                options={countryOptions}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="county"
                label={<TransField i18nKey="county" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="city"
                label={<TransField i18nKey="cityParish" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="street"
                label={<TransField i18nKey="streetHouseNumber" />}
              />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                name="isSearchable"
                options="BOOL"
                inline
                label={<TransField i18nKey="searchable" />}
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;" labelPosition="top">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    onClick={handleFilterReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;" labelPosition="top">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<Icon name="search" />}
                  >
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
