import type { FC } from 'react';
import { useSelector } from 'store/utils';
import { AddButton, CardHeader, Layout, Loadable } from '@fleet/shared';
import { UserForm } from 'routes/users/UserForm';
import { Route, Switch, useHistory } from 'react-router-dom';
import { UsersTable } from 'routes/users/UsersTable';
import { TransButton } from 'i18n/trans/button';
import { usersLoadingSelector } from 'features/loading/loadingSelectors';
import { TransNav } from 'i18n/trans/header';

interface UsersProps {}

export const Users: FC<UsersProps> = () => {
  const history = useHistory();
  const loading = useSelector(usersLoadingSelector);

  return (
    <Layout
      header={
        <CardHeader title={<TransNav i18nKey="users" />}>
          <AddButton onClick={() => history.push('/users/create')}>
            <TransButton i18nKey="add" />
          </AddButton>
        </CardHeader>
      }
      drawer={
        <Switch>
          <Route
            path="/users/:action(create|edit)/:id?"
            component={UserForm}
            exact
          />
        </Switch>
      }
    >
      <Loadable loading={loading}>
        <UsersTable />
      </Loadable>
    </Layout>
  );
};
