import { createReducer } from '@reduxjs/toolkit';
import { CodeList } from 'dto/codeList';
import {
  getCodeLists,
  setCodeList,
  updateCodeList,
} from 'features/codeList/codeListActions';

interface CodeListState {
  list: Array<CodeList>;
  current?: CodeList;
}

const initialState: CodeListState = {
  list: [],
};

export const codeListReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCodeLists.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(setCodeList, (state, action) => {
      state.current = action.payload;
    })
    .addCase(updateCodeList.fulfilled, (state, action) => {
      state.current = action.payload;
    });
});
