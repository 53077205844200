import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useModal } from '@fleet/shared/hooks';
import { Button, Grid } from '@mui/material';
import { Modal } from '@fleet/shared/mui';
import { FormProvider, Icon, api, formSubmit, useForm } from '@fleet/shared';
import { CheckboxGroupField, TextField } from '@fleet/shared/form';
import { User } from 'dto/user';
import { useDispatch, useSelector } from 'store/utils';
import { userSelector } from 'features/user/userSelectors';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { getUsers } from 'features/user/userActions';
import { TransModal } from 'i18n/trans/modal';
import { TransAlert } from 'i18n/trans/alert';

interface UserCopyModalProps {}

interface UserCopyFormValues extends Partial<User> {
  copy: Array<'roles' | 'organizations'>;
}

export const UserCopyModal: FC<UserCopyModalProps> = () => {
  const { open: isOpen, onOpen, onClose } = useModal();
  const currentUser = useSelector(userSelector)!;
  const initialValues = useMemo<UserCopyFormValues>(
    () => ({
      cultureId: currentUser.culture.id,
      validity: currentUser.validity,
      copy: ['roles', 'organizations'],
    }),
    [currentUser]
  );
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values: UserCopyFormValues) =>
      formSubmit(async () => {
        const { copy, ...user } = values;
        const { id } = (
          await api.post<User>('/users', {
            ...user,
            ...(copy.includes('roles') && {
              roles: currentUser.roles.map((role) => role.id),
            }),
            // TODO, organizations is not available in api
            ...(copy.includes('organizations') && {
              organizations: currentUser.organizations,
            }),
          })
        ).data;
        alert.success(<TransAlert i18nKey="newUserCreated" />);
        onClose();
        history.replace('/users');
        await dispatch(getUsers());
        history.push(`/users/edit/${id}`);
      }),
    [
      alert,
      currentUser.organizations,
      currentUser.roles,
      dispatch,
      history,
      onClose,
    ]
  );
  const { form, handleSubmit } = useForm<UserCopyFormValues>({
    initialValues,
    onSubmit,
  });

  return (
    <>
      <Button
        startIcon={<Icon name="duplicate" size={16} />}
        size="small"
        onClick={onOpen}
      >
        <TransButton i18nKey="copy" />
      </Button>
      <Modal
        open={isOpen}
        title={<TransModal i18nKey="copyUser" />}
        onClose={onClose}
        actionButton={
          <Button
            variant="contained"
            startIcon={<Icon name="duplicate" size={16} />}
            onClick={() => handleSubmit()}
          >
            <TransButton i18nKey="copy" />
          </Button>
        }
        maxWidth="xs"
      >
        <FormProvider {...form}>
          <Grid container rowSpacing={2}>
            <TextField
              name="username"
              label={<TransField i18nKey="username" />}
              margin="normal"
              required
            />
            <TextField
              name="firstName"
              label={<TransField i18nKey="firstName" />}
              margin="normal"
              required
            />
            <TextField
              name="lastName"
              label={<TransField i18nKey="lastName" />}
              margin="normal"
              required
            />
            <TextField
              name="email"
              label={<TransField i18nKey="email" />}
              margin="normal"
              required
            />
            <CheckboxGroupField
              name="copy"
              label={<>&nbsp;</>}
              options={[
                {
                  value: 'roles',
                  label: <TransField i18nKey="copyRoles" />,
                },
                // {
                //   value: 'organizations',
                //   label: <TransField i18nKey="copyRelatedOrganizations" />,
                // },
              ]}
              inline
            />
          </Grid>
        </FormProvider>
      </Modal>
    </>
  );
};
