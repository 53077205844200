import type { FC } from 'react';
import {
  assignUserRole,
  deleteUserRole,
  getRoles,
  getUser,
} from 'features/user/userActions';
import { useCallback, useEffect, useMemo } from 'react';
import { Column, useTable, useRowSelect, Hooks } from 'react-table';
import { Table } from '@fleet/shared';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import { useDispatch, useSelector } from 'store/utils';
import { userSelector, usersRolesSelector } from 'features/user/userSelectors';
import { UserRole } from 'dto/user';
import { TransTableHead } from 'i18n/trans/table';

interface UsersEditFormRolesProps {}

export const UserFormRoles: FC<UsersEditFormRolesProps> = () => {
  const user = useSelector(userSelector);
  const userId = user?.id;
  const roles = useSelector(usersRolesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch, user]);

  const columns = useMemo<Array<Column<UserRole>>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="role" />,
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
      },
    ],
    []
  );

  const initialState = useMemo(
    () => ({
      selectedRowIds:
        user?.roles.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}) ?? {},
    }),
    [user?.roles]
  );

  const getRowId = useCallback((row: UserRole) => row.id, []);
  const useCustomRowSelect = useMemo(
    () => (hooks: Hooks<UserRole>) => {
      hooks.getToggleRowSelectedProps.push((props, meta) => ({
        ...props,
        onChange: async (event) => {
          props.onChange?.(event);
          await dispatch(
            ((event.target as HTMLInputElement).checked
              ? assignUserRole
              : deleteUserRole)({
              userId: userId!,
              roleId: meta.row.id!,
            })
          ).unwrap();
          await dispatch(getUser(userId!));
        },
      }));
    },
    [dispatch, userId]
  );
  const table = useTable(
    {
      data: roles,
      columns,
      initialState,
      getRowId,
    },
    useRowSelect,
    useCustomRowSelect,
    useRowSelectCheckbox
  );

  return (
    <Table
      table={table}
      getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
      selectedColor="success"
    />
  );
};
