export enum ClassificationGroup {
  ADDRESS_TYPE = 'ADDRESS_TYPE',
  BANK_ACCOUNT_TYPE = 'BANK_ACCOUNT_TYPE',
  CARD_TYPE = 'CARD_TYPE',
  CARRIER = 'CARRIER',
  CONTACTING_OPTION_TYPE = 'CONTACTING_OPTION_TYPE',
  CONTACTING_PURPOSE_TYPE = 'CONTACTING_PURPOSE_TYPE',
  COUNTRY = 'COUNTRY',
  CULTURE = 'CULTURE',
  CURRENCY = 'CURRENCY',
  IMS = 'IMS',
  ORGANIZATION_CODE_LIST = 'ORGANIZATION_CODE_LIST',
  ORGANIZATION_FILE = 'ORGANIZATION_FILE',
  ORGANIZATION_ROLE = 'ORGANIZATION_ROLE',
  POINT_OF_SALE_TYPE = 'POINT_OF_SALE_TYPE',
  POS_TYPE = 'POS_TYPE',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  RETAILER = 'RETAILER',
  RETAILER_SERVICE_FEE_TYPE = 'RETAILER_SERVICE_FEE_TYPE',
  STOP_CODE_LIST = 'STOP_CODE_LIST',
  TEMPLATE_TYPE = 'TEMPLATE_TYPE',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.ADDRESS_TYPE,
  ClassificationGroup.BANK_ACCOUNT_TYPE,
  ClassificationGroup.CARD_TYPE,
  ClassificationGroup.CONTACTING_OPTION_TYPE,
  ClassificationGroup.CONTACTING_PURPOSE_TYPE,
  ClassificationGroup.CULTURE,
  ClassificationGroup.CURRENCY,
  ClassificationGroup.ORGANIZATION_FILE,
  ClassificationGroup.ORGANIZATION_ROLE,
  ClassificationGroup.POINT_OF_SALE_TYPE,
  ClassificationGroup.POS_TYPE,
  ClassificationGroup.PRODUCT_CATEGORY,
  ClassificationGroup.RETAILER_SERVICE_FEE_TYPE,
  ClassificationGroup.TEMPLATE_TYPE,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;
