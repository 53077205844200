import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  addressDelete: (
    <Trans i18nKey="alert.addressDelete" defaults="Addresses deleted" />
  ),
  allianceAdd: <Trans i18nKey="alert.allianceAdd" defaults="Alliance added" />,
  allianceDeleted: (
    <Trans i18nKey="alert.allianceDeleted" defaults="Alliance deleted" />
  ),
  allianceMembersDeleted: (
    <Trans
      i18nKey="alert.allianceMembersDeleted"
      defaults="Members organization deleted"
    />
  ),
  allianceUpdated: (
    <Trans i18nKey="alert.allianceUpdated" defaults="Alliance updated" />
  ),
  bookingFeeCreated: (
    <Trans i18nKey="alert.bookingFeeCreated" defaults="Booking fee created" />
  ),
  bookingFeeDeleted: (
    <Trans i18nKey="alert.bookingFeeDeleted" defaults="Booking fee deleted" />
  ),
  bookingFeeUpdated: (
    <Trans i18nKey="alert.bookingFeeUpdated" defaults="Booking fee updated" />
  ),
  cardTypeCreated: (
    <Trans i18nKey="alert.cardTypeCreated" defaults="Card type created" />
  ),
  cardTypeDeleted: (
    <Trans i18nKey="alert.cardTypeDeleted" defaults="Card type deleted" />
  ),
  cardTypeTranslationCreated: (
    <Trans
      i18nKey="alert.cardTypeTranslationCreated"
      defaults="Card type translation created"
    />
  ),
  cardTypeTranslationDeleted: (
    <Trans
      i18nKey="alert.cardTypeTranslationDeleted"
      defaults="Card type translation deleted"
    />
  ),
  cardTypeTranslationUpdated: (
    <Trans
      i18nKey="alert.cardTypeTranslationUpdated"
      defaults="Card type translation updated"
    />
  ),
  cardTypeUpdated: (
    <Trans i18nKey="alert.cardTypeUpdated" defaults="Card type updated" />
  ),
  changes: <Trans i18nKey="alert.changes" defaults="Unsaved changes" />,
  imsRegisterCreated: (
    <Trans i18nKey="alert.imsRegisterCreated" defaults="IMS register created" />
  ),
  imsRegisterSaved: (
    <Trans i18nKey="alert.imsRegisterSaved" defaults="IMS register saved" />
  ),
  newUserCreated: (
    <Trans i18nKey="alert.newUserCreated" defaults="New user created" />
  ),
  organizationCodeListCreated: (
    <Trans
      i18nKey="alert.organizationCodeListCreated"
      defaults="Organization code list created"
    />
  ),
  organizationCodeListDeleted: (
    <Trans
      i18nKey="alert.organizationCodeListDeleted"
      defaults="Organization code list deleted"
    />
  ),
  organizationCodeListSaved: (
    <Trans
      i18nKey="alert.organizationCodeListSaved"
      defaults="Organization code list saved"
    />
  ),
  organizationCreated: (
    <Trans
      i18nKey="alert.organizationCreated"
      defaults="Organization created"
    />
  ),
  organizationDeleted: (
    <Trans
      i18nKey="alert.organizationDeleted"
      defaults="Organization deleted"
    />
  ),
  organizationFileCreated: (
    <Trans
      i18nKey="alert.organizationFileCreated"
      defaults="Organization file created"
    />
  ),
  organizationFileDeleted: (
    <Trans
      i18nKey="alert.organizationFileDeleted"
      defaults="Organization file deleted"
    />
  ),
  organizationFileUpdated: (
    <Trans
      i18nKey="alert.organizationFileUpdated"
      defaults="Organization file updated"
    />
  ),
  passwordChanged: (
    <Trans i18nKey="alert.passwordChanged" defaults="Password changed" />
  ),
  passwordResetLinkSent: (
    <Trans
      i18nKey="alert.passwordResetLinkSent"
      defaults="Password reset link sent"
    />
  ),
  saved: <Trans i18nKey="alert.saved" defaults="All changes saved" />,
  saving: <Trans i18nKey="alert.saving" defaults="Saving changes" />,
  stopAdd: <Trans i18nKey="alert.stopAdd" defaults="New stop added" />,
  stopCodeListCreated: (
    <Trans
      i18nKey="alert.stopCodeListCreated"
      defaults="Stop code list created"
    />
  ),
  stopCodeListDeleted: (
    <Trans
      i18nKey="alert.stopCodeListDeleted"
      defaults="Stop code list deleted"
    />
  ),
  stopCodeListSaved: (
    <Trans i18nKey="alert.stopCodeListSaved" defaults="Stop code list saved" />
  ),
  stopDelete: <Trans i18nKey="alert.stopDelete" defaults="Stop was deleted" />,
  stopUpdate: <Trans i18nKey="alert.stopUpdate" defaults="Stop was updated" />,
  userCreated: <Trans i18nKey="alert.userCreated" defaults="New user added" />,
  userDeleted: <Trans i18nKey="alert.userDeleted" defaults="User deleted" />,
  userUpdated: (
    <Trans i18nKey="alert.userUpdated" defaults="User data updated" />
  ),
});
