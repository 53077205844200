import type { FC } from 'react';
import { MouseEvent, useCallback, useMemo } from 'react';
import { Icon, Table, TableColumns } from '@fleet/shared';
import { useTable } from 'react-table';
import { TransTableHead } from 'i18n/trans/table';
import { OrganizationsAlliances } from 'dto/alliance';
import { AllianceOrganizationsAddRelatedModal } from 'routes/alliances/AllianceOrganizationsAddRelatedModal';
import { useDispatch, useSelector } from 'store/utils';
import { alliancesSelector } from 'features/alliance/allianceSelectors';
import {
  deleteAllianceOrganization,
  getAlliance,
} from 'features/alliance/allianceActions';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { IconButton } from '@mui/material';

interface AlliancesFormMembersProps {
  data?: Array<OrganizationsAlliances>;
}

export const AllianceMembersTable: FC<AlliancesFormMembersProps> = ({
  data,
}) => {
  const currentAlliance = useSelector(alliancesSelector);
  const currentAllianceId = currentAlliance?.id;
  const alert = useAlert();
  const dispatch = useDispatch();

  const getRowId = useCallback(
    (row: OrganizationsAlliances) => row.organization.id,
    []
  );
  const handleRemoveOrganization = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.currentTarget.disabled = true;
      const organizationId = event.currentTarget.id;
      try {
        await dispatch(
          deleteAllianceOrganization({
            allianceId: currentAllianceId!,
            organizationId,
          })
        ).unwrap();
        alert.success(<TransAlert i18nKey="allianceMembersDeleted" />);
        await dispatch(getAlliance(currentAllianceId!));
      } catch (e) {
        event.currentTarget.disabled = false;
      }
    },
    [alert, currentAllianceId, dispatch]
  );
  const columns = useMemo<TableColumns<OrganizationsAlliances>>(
    () => [
      {
        id: 'organization',
        accessor: ({ organization: { name } }) => name,
        Header: <TransTableHead i18nKey="organization" />,
        width: '100%',
      },
      {
        id: 'id',
        accessor: ({ organization: { id } }) => (
          <IconButton
            id={id}
            onClick={handleRemoveOrganization}
            color="inherit"
          >
            <Icon name="delete" />
          </IconButton>
        ),
        width: 'auto',
      },
    ],
    [handleRemoveOrganization]
  );

  const table = useTable({
    data: data ?? [],
    columns,
    getRowId,
  });

  return (
    <Table
      table={table}
      caption={<AllianceOrganizationsAddRelatedModal />}
      getHeaderGroupProps={{ sx: { backgroundColor: 'common.white' } }}
    />
  );
};
