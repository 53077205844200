import { createReducer } from '@reduxjs/toolkit';
import {
  cleanOrganizationsSearch,
  getOrganizationsList,
  getSalesPointClient,
  removeUnits,
  setCurrentOrganization,
  setOrganizationsFilter,
  updateOrCreateUnit,
} from 'features/organization/organizationActions';
import {
  Application,
  Organization,
  OrganizationSearchFilter,
} from 'dto/organization';
import { Pagination } from '@fleet/shared/dto/pagination';

interface OrganizationState {
  search?: Pagination<Organization>;
  current?: Organization;
  filter: Partial<OrganizationSearchFilter>;
  clientId?: Array<Application>;
}

const initialState: OrganizationState = {
  filter: {},
};

export const organizationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getOrganizationsList.rejected, (state) => {
      state.search = undefined;
    })
    .addCase(getOrganizationsList.fulfilled, (state, action) => {
      state.search = action.payload;
    })
    .addCase(setCurrentOrganization, (state, action) => {
      state.current = action.payload;
    })
    .addCase(getSalesPointClient.fulfilled, (state, action) => {
      state.clientId = action.payload;
    })
    .addCase(setOrganizationsFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(cleanOrganizationsSearch, (state) => {
      state.search = undefined;
    })
    .addCase(updateOrCreateUnit.fulfilled, (state, action) => {
      if (!state.current) {
        return;
      }

      const { payload } = action;
      if (!state.current.units.length) {
        state.current.units = [payload];
      }

      const editedUnitIdx = state.current.units
        .map(({ id }) => id)
        .indexOf(payload.id);
      if (editedUnitIdx !== -1) {
        state.current.units[editedUnitIdx] = payload;
        return;
      }

      state.current.units.push(payload);
    })
    .addCase(removeUnits.fulfilled, (state, action) => {
      state.current!.units = state.current!.units.filter(
        ({ id }) => !action.payload.includes(id)
      );
    });
});
