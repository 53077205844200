import { createReducer } from '@reduxjs/toolkit';
import { Alliance, OrganizationsAlliances } from 'dto/alliance';
import {
  clearAlliance,
  clearAllianceList,
  getAlliance,
  getAlliancesList,
  setAlliance,
  updateAlliance,
} from 'features/alliance/allianceActions';

interface AlliancesState {
  list: Array<Alliance>;
  current?: Alliance;
  organizations?: Array<OrganizationsAlliances>;
}

const initialState: AlliancesState = {
  list: [],
};

export const allianceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearAllianceList, (state) => {
      state.list = [];
    })
    .addCase(getAlliancesList.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(getAlliance.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(setAlliance, (state, action) => {
      state.current = action.payload;
    })
    .addCase(clearAlliance, (state) => {
      state.current = undefined;
    })
    .addCase(updateAlliance.fulfilled, (state, action) => {
      state.current = action.payload;
    });
});
