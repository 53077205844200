import { FC, useEffect, useMemo, useCallback } from 'react';
import { Button, Stack } from '@mui/material';
import { useModal } from '@fleet/shared/hooks';
import { Modal, TextField, Loadable, Icon, formSubmit } from '@fleet/shared';
import { FormProvider, useForm } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { Application } from 'dto/organization';
import { useDispatch, useSelector } from 'store/utils';
import {
  getSalesPointClient,
  createSalesPointClient,
} from 'features/organization/organizationActions';
import { clientIdSelector } from 'features/organization/organizationSelectors';
import { salesPointClientIdModalLoadingSelector } from 'features/loading/loadingSelectors';
import { TransTitle } from 'i18n/trans/title';
import { TransField } from 'i18n/trans/field';
import { Classifier } from '@fleet/shared/dto/classifier';

const useStyles = makeStyles(
  () => ({
    root: {},
    clientId: {
      marginBottom: 16,
    },
    paper: {
      minHeight: 400,
      minWidth: 400,
      '& .MuiDialogActions-root': {
        display: 'none',
      },
    },
  }),
  {
    name: 'UserForm',
  }
);
interface ClientIdModalProps extends Classifier {}

export const PointOfSaleClientIdModal: FC<ClientIdModalProps> = ({
  name,
  id,
}) => {
  const { open: isOpen, onOpen, onClose } = useModal();
  const dispatch = useDispatch();
  const loading = useSelector(salesPointClientIdModalLoadingSelector);
  useEffect(() => {
    dispatch(getSalesPointClient(id));
  }, [dispatch, id, isOpen]);
  const clientIdInfo = useSelector(clientIdSelector);

  const initialValues = useMemo(() => {
    if (clientIdInfo && clientIdInfo.length > 0) {
      return { ...clientIdInfo[0] };
    } else return {};
  }, [clientIdInfo]);

  const onSubmit = useCallback(
    (application: Application) =>
      formSubmit(async () => {
        await dispatch(
          createSalesPointClient({
            name,
            clientId: application.clientId,
            pointOfSaleId: id,
          })
        );
        await dispatch(getSalesPointClient(id));
      }),
    [dispatch, id, name]
  );

  const { form, handleSubmit } = useForm<Application>({
    initialValues,
    onSubmit,
    subscription: { submitting: true },
  });

  const { reset } = form;

  useEffect(() => {
    if (!clientIdInfo || clientIdInfo?.length == 0) {
      reset({});
    }
  }, [reset, clientIdInfo]);

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
  };
  ``;
  const classes = useStyles();
  return (
    <>
      <Button onClick={onOpen}>{'Client ID'}</Button>

      <Modal
        open={isOpen}
        title={<TransTitle i18nKey="clientIdModal" values={{ name: name }} />}
        onClose={onClose}
        maxWidth="xs"
        fullWidth={true}
        classes={{ paper: classes.paper }}
        actionButton={<></>}
      >
        <Loadable loading={loading}>
          <FormProvider {...form}>
            {clientIdInfo && clientIdInfo.length > 0 ? (
              <Stack direction="row" alignItems="center">
                <TextField
                  className={classes.clientId}
                  name="clientId"
                  label={<TransField i18nKey="clientId" />}
                  disabled
                />
                <Button
                  variant="text"
                  sx={{ color: '#293845', mr: '0', pr: 0 }}
                  endIcon={<Icon name="duplicate" size={16} />}
                  onClick={() => handleCopy(form.getState().values.clientId)}
                ></Button>
              </Stack>
            ) : (
              <Stack direction="row" alignItems="center">
                <TextField
                  className={classes.clientId}
                  name="clientId"
                  label={<TransField i18nKey="clientId" />}
                />
                <Button
                  onClick={() => handleSubmit()}
                  endIcon={
                    <Icon name="check" size={20} sx={{ color: 'gray' }} />
                  }
                  sx={{ pr: 0 }}
                ></Button>
              </Stack>
            )}

            {clientIdInfo && clientIdInfo.length > 0 && (
              <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <TextField name="clientSecret" label="Client Secret" disabled />

                <Button
                  variant="text"
                  sx={{ color: '#293845', pr: 0 }}
                  endIcon={<Icon name="duplicate" size={16} />}
                  onClick={() =>
                    handleCopy(form.getState().values.clientSecret)
                  }
                ></Button>
              </Stack>
            )}
          </FormProvider>
        </Loadable>
      </Modal>
    </>
  );
};
