import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { classificationReducer } from 'features/classification/classificationReducer';
import { auditReducer } from 'features/audit/auditReducer';
import { userReducer } from 'features/user/userReducer';
import { organizationReducer } from 'features/organization/organizationReducer';
import { allianceReducer } from 'features/alliance/allianceReducer';
import { stopReducer } from 'features/stop/stopReducer';
import { imsRegisterReducer } from 'features/imsRegister/imsRegisterReducer';
import { codeListReducer } from 'features/codeList/codeListReducer';
import { commonReducer } from 'features/common/commonReducer';
import { cardTypeReducer } from 'features/cardType/cardTypeReducer';
import { bookingFeeReducer } from 'features/bookingFee/bookingFeeReducer';

export default combineReducers({
  loading: loadingReducer,
  classification: classificationReducer,
  audit: auditReducer,
  user: userReducer,
  organization: organizationReducer,
  alliances: allianceReducer,
  stops: stopReducer,
  imsRegister: imsRegisterReducer,
  codeLists: codeListReducer,
  common: commonReducer,
  cardTypes: cardTypeReducer,
  bookingFee: bookingFeeReducer,
});
