import { useDispatch, useSelector } from 'store/utils';
import { ClassificationGroup } from 'dto/classification';
import { filesLoadingSelector } from 'features/loading/loadingSelectors';
import React, { FC, useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import {
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
  useRowEditActions,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { TransAlert } from 'i18n/trans/alert';
import { useRowSelect } from 'react-table';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import {
  deleteOrganizationFiles,
  getOrganization,
  updateOrCreateOrganizationFile,
} from 'features/organization/organizationActions';
import { OrganizationFile } from 'dto/organization';
import { getBaseDataFile } from 'features/files/fileActions';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useHighLightAuditRow } from 'routes/organizations/hooks/useHighLightAuditRow';

interface FilesProps {
  data: Array<OrganizationFile>;
}

export const Files: FC<FilesProps> = ({ data }) => {
  const fileTypes = useClassificationOptions(
    ClassificationGroup.ORGANIZATION_FILE
  );
  const loading = useSelector(filesLoadingSelector);
  const dispatch = useDispatch();
  const alert = useAlert();

  const columns = useMemo<TableColumns<OrganizationFile>>(
    () => [
      {
        id: 'typeId',
        accessor: ({ typeId }) => typeId,
        Header: <TransTableHead i18nKey="fileType" />,
        editableProps: {
          options: fileTypes,
        },
        type: 'select',
      },
      {
        id: 'name',
        accessor: 'name',
        Header: <TransTableHead i18nKey="file" />,
        editableProps: {
          multiple: false,
        },
        type: 'file',
        wrapper: (field, cell) => {
          if (Boolean(cell.row.state.editable)) {
            return field;
          }

          const fileId = cell.row.original.fileId;
          const fileName = cell.row.original.name;

          return (
            <Button
              sx={{ paddingLeft: 0, minWidth: 0 }}
              variant="text"
              onClick={() => dispatch(getBaseDataFile({ fileId, fileName }))}
            >
              {cell.row.original.name}
            </Button>
          );
        },
      },
    ],
    [dispatch, fileTypes]
  );

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      form,
      data,
      columns,
      onRowUpdate: async (organizationFile) => {
        const formData = new FormData();
        formData.append('File', organizationFile.name as unknown as Blob);
        formData.append('TypeId', organizationFile.typeId);

        await dispatch(
          updateOrCreateOrganizationFile({
            payload: formData,
            fileEntityId: organizationFile.id,
          })
        ).unwrap();
        dispatch(getOrganization());
        alert.success(
          <TransAlert
            i18nKey={
              organizationFile.id
                ? 'organizationFileUpdated'
                : 'organizationFileCreated'
            }
          />
        );
      },
    },
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowSelect,
    useRowActive
  );
  useHighLightAuditRow(table, data);
  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteOrganizationFiles(
        table.selectedFlatRows.map((row) => row.original.id)
      )
    ).unwrap();

    await dispatch(getOrganization());
    alert.success(<TransAlert i18nKey="organizationFileDeleted" />);
  }, [alert, dispatch, table.selectedFlatRows]);

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <Box sx={{ mb: 6 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 'auto' }}
            >
              <Button
                variant="text"
                onClick={removeSelectedRows}
                startIcon={<Icon name="delete" />}
                disabled={!Object.keys(table.state.selectedRowIds).length}
                color="error"
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
              <Button
                variant="text"
                onClick={addRow}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
          <Table table={table} />
          {Boolean(!table.rows.length) && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ m: 2, fontSize: 14 }}
            >
              <TransSubtitle i18nKey="noFilesFound" />
            </Typography>
          )}
        </Box>
      </FormProvider>
    </Loadable>
  );
};
