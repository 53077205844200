import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { CardHeader } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { cardTypesLoadingSelector } from 'features/loading/loadingSelectors';
import { CardContent, Grid } from '@mui/material';
import { Layout } from '@fleet/shared/components/Layout';
import { AddButton, Loadable, SearchCard, SearchResult } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { getCardTypes } from 'features/cardType/cardTypeActions';
import { cardTypesSelector } from 'features/cardType/cardTypeSelectors';
import { CardTypeForm } from 'routes/configuration/cardTypes/CardTypeForm';
import { TransField } from 'i18n/trans/field';

interface CardTypesProps {}

export const CardTypes: FC<CardTypesProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(cardTypesLoadingSelector);
  const cardTypes = useSelector(cardTypesSelector);

  useEffect(() => {
    dispatch(getCardTypes());
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="cardTypes" />}>
            <AddButton
              onClick={() => history.push('/configuration/card-types/create')}
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/configuration/card-types/:action(create|edit)/:id?"
              component={CardTypeForm}
              exact
            />
          </Switch>
        }
      >
        <SearchResult
          results={cardTypes.length}
          i18nKey="noDataAdded"
          loading={loading}
        >
          <CardContent sx={{ p: 2, flexGrow: 1 }}>
            <Grid container spacing={3}>
              {cardTypes.map(
                ({ id, name, type, isCardNumberMandatory, issuer }) => (
                  <Grid key={id} item sm={4} md={3}>
                    <SearchCard
                      title={name}
                      list={[
                        [
                          'type',
                          <TransTitle i18nKey="category" />,
                          type ? type.name : '-',
                        ],
                        [
                          'isCardNumberMandatory',
                          <TransTitle i18nKey="mandatoryCardNumber" />,
                          <TransField
                            i18nKey={isCardNumberMandatory ? 'yes' : 'no'}
                          />,
                        ],
                        [
                          'issues',
                          <TransTitle i18nKey="issuer" />,
                          issuer ? issuer.name : '-',
                        ],
                      ]}
                      {...{
                        component: Link,
                        to: `/configuration/card-types/edit/${id}`,
                      }}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </CardContent>
        </SearchResult>
      </Layout>
    </Loadable>
  );
};
