import { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import type { TabsProps } from '@fleet/shared/mui/Tabs';
import { TabPanel, Tabs } from '@fleet/shared';
import { useSelector } from 'store/utils';
import { currentOrganizationSelector } from 'features/organization/organizationSelectors';
import { Units } from 'routes/organizations/Tabs/Units';
import { PointsOfSale } from 'routes/organizations/Tabs/PointsOfSale';
import { Addresses } from 'routes/organizations/Tabs/Addresses';
import { ContactOptions } from 'routes/organizations/Tabs/ContactOptions';
import { TicketTexts } from 'routes/organizations/Tabs/TicketTexts';
import { Typography } from '@mui/material';
import { BookingAccessRights } from 'routes/organizations/Tabs/BookingAccessRights';
import { ServiceFees } from 'routes/organizations/Tabs/ServiceFees';
import { BankAccounts } from 'routes/organizations/Tabs/BankAccounts';
import { StopCodes } from 'routes/organizations/Tabs/StopCodes';
import { useHistory, useLocation } from 'react-router-dom';
import {
  OrganizationLocationState,
  OrganizationTab,
  organizationTabs,
} from 'dto/organization';
import { TransTab } from 'i18n/trans/tab';
import { Files } from 'routes/organizations/Tabs/Files';

const useStyles = makeStyles(
  (theme) => ({
    root: { padding: theme.spacing(3) },
  }),
  {
    name: 'OrganizationTabs',
  }
);

export const OrganizationTabs = () => {
  const classes = useStyles();
  const history = useHistory();
  const organization = useSelector(currentOrganizationSelector)!;
  const location = useLocation<OrganizationLocationState>();
  const [tabValue, setTabValue] = useState(
    location.state?.tab ?? organizationTabs[0]
  );

  const handleTabChange = useCallback<Required<TabsProps>['onChange']>(
    (_, value) => {
      setTabValue(value);
      history.replace(location.pathname, { state: undefined });
    },
    [history, location.pathname]
  );

  const renderTab = useCallback(
    (name: OrganizationTab) => {
      switch (name) {
        case 'units':
          return <Units data={organization[name]} />;
        case 'pointsOfSale':
          return <PointsOfSale data={organization[name]} />;
        case 'addresses':
          return <Addresses data={organization[name]} />;
        case 'contactingOptions':
          return <ContactOptions data={organization[name]} />;
        case 'serviceFees':
          return <ServiceFees data={organization[name]} />;
        case 'bookingAccessRights':
          return <BookingAccessRights data={organization[name]} />;
        case 'stopCodes':
          return <StopCodes data={organization[name]} />;
        case 'bankAccounts':
          return <BankAccounts data={organization[name]} />;
        case 'ticketTexts':
          return <TicketTexts data={organization[name]} />;
        case 'files':
          return <Files data={organization[name]} />;
      }
    },
    [organization]
  );

  return (
    <div className={classes.root}>
      <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable">
        {organizationTabs.map((tab) => (
          <TabPanel
            key={tab}
            label={
              <>
                <TransTab i18nKey={tab} />
                &nbsp;
                <Typography
                  component="span"
                  variant="body2"
                >{`(${organization?.[tab].length})`}</Typography>
              </>
            }
            value={tab}
          >
            {renderTab(tab)}
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};
