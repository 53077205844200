import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { getCodeLists } from 'features/codeList/codeListActions';
import { codeListsSelector } from 'features/codeList/codeListSelectors';
import { codeListsLoadingSelector } from 'features/loading/loadingSelectors';
import {
  AddButton,
  Layout,
  Loadable,
  SearchCard,
  SearchResult,
} from '@fleet/shared';
import { CardHeader } from '@fleet/shared/mui';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { CardContent, Grid } from '@mui/material';
import { OrganizationCodeListForm } from 'routes/configuration/organizationCodeLists/OrganizationCodeListForm';
import { TransField } from 'i18n/trans/field';

interface OrganizationCodeListsProps {}

export const OrganizationCodeLists: FC<OrganizationCodeListsProps> = () => {
  const loading = useSelector(codeListsLoadingSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const organizationCodeLists = useSelector(codeListsSelector);

  useEffect(() => {
    dispatch(getCodeLists('organization'));
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="organizationCodeList" />}>
            <AddButton
              onClick={() =>
                history.push('/configuration/organization-code-lists/create')
              }
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/configuration/organization-code-lists/:action(create|edit)/:id?"
              component={OrganizationCodeListForm}
              exact
            />
          </Switch>
        }
      >
        <SearchResult
          results={organizationCodeLists.length}
          i18nKey="noDataAdded"
          loading={loading}
        >
          <CardContent sx={{ p: 2, flexGrow: 1 }}>
            <Grid container spacing={3}>
              {organizationCodeLists.map(
                ({ id, name, prefix, hasUniqueCodes }) => (
                  <Grid key={id} item sm={4} md={3}>
                    <SearchCard
                      title={name}
                      list={[
                        [
                          'prefix',
                          <TransTitle i18nKey="codeListPrefix" />,
                          prefix || '-',
                        ],
                        [
                          'hasUniqueCode',
                          <TransTitle i18nKey="uniqueCodes" />,
                          <TransField
                            i18nKey={hasUniqueCodes ? 'yes' : 'no'}
                          />,
                        ],
                      ]}
                      {...{
                        component: Link,
                        to: `/configuration/organization-code-lists/edit/${id}`,
                      }}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </CardContent>
        </SearchResult>
      </Layout>
    </Loadable>
  );
};
