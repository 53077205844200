import { FC, useCallback, useMemo } from 'react';
import {
  DateField,
  FormProvider,
  formSubmit,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Button, FormControl } from '@fleet/shared/mui';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  auditActionTypes,
  AuditSearchFormValues,
  auditTrailTypes,
} from 'dto/audit';
import { useDispatch, useSelector } from 'store/utils';
import {
  getAuditTrail,
  setAuditTrailFilter,
} from 'features/audit/auditActions';
import { auditFilterSelector } from 'features/audit/auditSelectors';
import { useTranslation } from 'react-i18next';

interface AuditSearchFormProps {}

export const AuditSearchForm: FC<AuditSearchFormProps> = () => {
  const dispatch = useDispatch();
  const filter = useSelector(auditFilterSelector);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    (values: AuditSearchFormValues) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getAuditTrail({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const { form, handleSubmit } = useForm<AuditSearchFormValues>({
    onSubmit,
    initialValues: filter,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setAuditTrailFilter({}));
  }, [dispatch, form]);

  const auditTrailTypeOptions = useMemo(
    () =>
      auditTrailTypes.map((value) => ({
        value,
        label: t(`field.auditTrailType.option.${value}`),
      })),
    [t]
  );
  const auditActionTypeOptions = useMemo(
    () =>
      auditActionTypes.map((value) => ({
        value,
        label: t(`field.auditActionType.option.${value}`),
      })),
    [t]
  );

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <DateField
                name="validity"
                label={<TransField i18nKey="dateTimeRange" />}
                selectsRange
                showTimeInput
                isClearable
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="userName"
                label={<TransField i18nKey="username" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="auditTrailType"
                label={<TransField i18nKey="entity" />}
                options={auditTrailTypeOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="auditActionType"
                label={<TransField i18nKey="auditActionType" />}
                options={auditActionTypeOptions}
                showEmptyOption
              />
            </Grid>

            <Grid item xs={1}>
              <TextField name="id" label={<TransField i18nKey="id" />} />
            </Grid>

            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
