import { FC } from 'react';
import { useCallback, useMemo } from 'react';
import {
  FormProvider,
  Icon,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { useRowSelect } from 'react-table';
import { Button, Stack } from '@mui/material';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import {
  useClassificationMap,
  useClassificationOptions,
} from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { currentCardTypeSelector } from 'features/cardType/cardTypeSelectors';
import { CardTypeTranslation } from 'dto/cardType';
import {
  createOrUpdateCardTypeLocalization,
  deleteCardTypeLocalization,
  getCardType,
} from 'features/cardType/cardTypeActions';
import { useParams } from 'react-router-dom';

export const CardTypeTranslations: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const alert = useAlert();
  const dispatch = useDispatch();
  const currentCardType = useSelector(currentCardTypeSelector);
  const translations = useMemo(
    () => currentCardType?.translations ?? [],
    [currentCardType?.translations]
  );
  const cultures = useClassificationMap(ClassificationGroup.CULTURE);
  const cultureOptions = useClassificationOptions(ClassificationGroup.CULTURE);

  const usedTranslations = useMemo(
    () => translations && translations.map(({ culture }) => culture.id),
    [translations]
  );
  const availableCultureOptions = useMemo(
    () =>
      cultureOptions.filter(({ value }) => !usedTranslations?.includes(value)),
    [cultureOptions, usedTranslations]
  );

  const columns: TableColumns<CardTypeTranslation> = useMemo(
    () => [
      {
        id: 'culture.id',
        accessor: ({ culture }) => culture?.id,
        Header: <TransTableHead i18nKey="language" />,
        type: 'select',
        editableProps: ({ value }) => ({
          options: [
            value && { value, label: cultures.get(value) },
            ...availableCultureOptions,
          ].filter(Boolean),
        }),
        width: '50%',
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        width: '50%',
      },
    ],
    [availableCultureOptions, cultures]
  );
  const { form } = useForm<{ rows: Array<CardTypeTranslation> }>({
    initialValues: {
      rows: translations,
    },
  });

  const handleRowUpdate = useCallback(
    async (values: CardTypeTranslation) => {
      const payload = {
        ...values,
        cardTypeId: id,
        cultureId: values.culture.id,
      };

      await dispatch(createOrUpdateCardTypeLocalization(payload)).unwrap();
      alert.success(
        values.id ? (
          <TransAlert i18nKey="cardTypeTranslationUpdated" />
        ) : (
          <TransAlert i18nKey="cardTypeTranslationCreated" />
        )
      );

      dispatch(getCardType(`${currentCardType?.id}`));
    },
    [alert, currentCardType?.id, dispatch, id]
  );

  const table = useFormTable<CardTypeTranslation>(
    {
      data: translations,
      columns,
      form,
      onRowUpdate: handleRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemoved = useCallback(
    async (rows: Array<CardTypeTranslation>) => {
      await Promise.all(
        rows.map((row) =>
          dispatch(
            deleteCardTypeLocalization({
              ...row,
              cardTypeId: id,
            })
          ).unwrap()
        )
      );

      alert.success(<TransAlert i18nKey="cardTypeTranslationDeleted" />);
      dispatch(getCardType(`${currentCardType?.id}`));
    },
    [alert, currentCardType?.id, dispatch, id]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemoved,
  });

  return (
    <FormProvider {...form}>
      <Table
        caption={
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ mb: 1, gap: '10px' }}
            >
              <Button
                startIcon={<Icon name="trash" />}
                onClick={removeSelectedRows}
                disabled={!Object.keys(table.state.selectedRowIds).length}
                color="error"
              >
                <TransButton i18nKey="delete" />
              </Button>
              <Button startIcon={<Icon name="plus" />} onClick={addRow}>
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
        }
        getTableProps={{ sx: { tableLayout: 'fixed' } }}
        getHeaderProps={{ style: { backgroundColor: 'white' } }}
        table={table}
      />
    </FormProvider>
  );
};
