import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransNav = createTrans({
  alliances: <Trans i18nKey="nav.alliances" defaults="Alliances" />,
  audit: <Trans i18nKey="nav.audit" defaults="Audit" />,
  bookingFee: <Trans i18nKey="nav.bookingFee" defaults="Booking fee" />,
  configuration: (
    <Trans i18nKey="nav.configuration.label" defaults="Configuration" />
  ),
  'configuration.cardTypes': (
    <Trans i18nKey="nav.configuration.cardTypes" defaults="Card types" />
  ),
  'configuration.imsRegisters': (
    <Trans i18nKey="nav.configuration.imsRegisters" defaults="IMS registers" />
  ),
  'configuration.organizationCodeLists': (
    <Trans
      i18nKey="nav.configuration.organizationCodeLists"
      defaults="Organization code lists"
    />
  ),
  'configuration.stopCodeLists': (
    <Trans
      i18nKey="nav.configuration.stopCodeLists"
      defaults="Stop code lists"
    />
  ),
  organizations: <Trans i18nKey="nav.organizations" defaults="Organizations" />,
  stops: <Trans i18nKey="nav.stops" defaults="Stops" />,
  users: <Trans i18nKey="nav.users" defaults="Users" />,
});
