import { FC, useCallback, useMemo } from 'react';
import { useRowSelect } from 'react-table';
import {
  FormProvider,
  Icon,
  Table,
  TableColumns,
  useForm,
} from '@fleet/shared';
import { Box, Button, Stack } from '@mui/material';
import {
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useDispatch, useSelector } from 'store/utils';
import { currentStopSelector } from 'features/stop/stopSelectors';
import {
  addOrUpdateStopLocalization,
  deleteLocalizationsStop,
  getStop,
} from 'features/stop/stopActions';
import { StopLocalizationResponse } from 'dto/stop';

interface StopLocalizationsTableProps {
  stopId: string;
}

export const StopLocalizationsTable: FC<StopLocalizationsTableProps> = ({
  stopId,
}) => {
  const languages = useClassificationOptions(ClassificationGroup.CULTURE);
  const dispatch = useDispatch();
  const currentStop = useSelector(currentStopSelector)!;

  const { form } = useForm<{ rows: Array<StopLocalizationResponse> }>({
    initialValues: {
      rows: currentStop.localizations,
    },
  });

  const columns = useMemo<TableColumns<StopLocalizationResponse>>(
    () => [
      {
        id: 'culture.id',
        accessor: ({ culture }) => culture?.id,
        type: 'select',
        editableProps: {
          options: languages,
        },
        Header: <TransTableHead i18nKey="language" />,
        width: '40%',
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        width: '60%',
      },
    ],
    [languages]
  );

  const onRowsRemove = useCallback(
    async (localizationList: Array<StopLocalizationResponse>) => {
      const stopLocalizationIds = localizationList.map(
        (localization) => localization.id
      );

      await dispatch(deleteLocalizationsStop(stopLocalizationIds));
      dispatch(getStop(stopId));
    },
    [dispatch, stopId]
  );

  const handleUpdateRows = useCallback(
    async (value) => {
      await dispatch(
        addOrUpdateStopLocalization({
          payload: { ...value, cultureId: value.culture.id },
          stopId: stopId,
        })
      ).unwrap();
      dispatch(getStop(stopId));
    },
    [dispatch, stopId]
  );

  const formTable = useFormTable<StopLocalizationResponse>(
    {
      data: currentStop.localizations,
      columns,
      form,
      onRowUpdate: handleUpdateRows,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table: formTable,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <FormProvider {...form}>
      <Box sx={{ mb: 6 }}>
        <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
          <Button
            variant="text"
            onClick={removeSelectedRows}
            startIcon={<Icon name="delete" />}
          >
            <TransButton i18nKey="deleteSelected" />
          </Button>
          <Button
            variant="text"
            onClick={addRow}
            startIcon={<Icon name="plus" />}
          >
            <TransButton i18nKey="addNew" />
          </Button>
        </Stack>
        <Table
          getHeaderGroupProps={{
            sx: {
              background: 'white',
            },
          }}
          table={formTable}
        />
      </Box>
    </FormProvider>
  );
};
