import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {
  CardHeader,
  DateField,
  DrawerForm,
  DrawerFormProps,
  FormProvider,
  Icon,
  Loadable,
  SelectField,
  TextField,
  formSubmit,
  useForm,
  useLoaded,
} from '@fleet/shared';
import { UserFormRoles } from 'routes/users/UserFormRoles';
import { UserFormOrganizations } from 'routes/users/UserFormOrganizations';
import { Accordion, AccordionPanel, Tooltip } from '@fleet/shared/mui';
import { UserCopyModal } from 'routes/users/modal/UserCopyModal';
import { UserPasswordChangeModal } from 'routes/users/modal/UserPasswordChangeModal';
import { UserPasswordResetModal } from 'routes/users/modal/UserPasswordResetModal';
import { UserDeleteModal } from 'routes/users/modal/UserDeleteModal';
import { User } from 'dto/user';
import { useDispatch, useSelector } from 'store/utils';
import { userSelector } from 'features/user/userSelectors';
import { fullName } from 'helpers/user';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { currentUserLoadingSelector } from 'features/loading/loadingSelectors';
import { useHistory, useParams } from 'react-router-dom';
import {
  getUser,
  getUserAvailablePasswordPolicies,
  getUsers,
  setUser,
  setUserAvailablePasswordPolicies,
  updateOrCreateUser,
} from 'features/user/userActions';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { UserFormPrimaryFields } from './UserFormPrimaryFields';
import { omit } from 'lodash';
import { RadioGroupField } from '@fleet/shared/form';
import { loggedInUserSelector } from 'features/common/commonSelectors';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'UserForm',
  }
);

export interface UserFormProps {}

export const UserForm: FC<UserFormProps> = () => {
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUser());
    if (action === 'edit' && id) {
      dispatch(getUser(id));
      dispatch(getUserAvailablePasswordPolicies(id));
    }
    return () => {
      dispatch(setUser());
      dispatch(setUserAvailablePasswordPolicies([]));
    };
  }, [action, dispatch, id]);

  const currentUser = useSelector(userSelector);
  const loggedInUser = useSelector(loggedInUserSelector);
  const currentUserId = currentUser?.id;
  const history = useHistory();
  const alert = useAlert();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );
  const onSubmit = useCallback(
    (user: User) =>
      formSubmit(async () => {
        const data = await dispatch(
          updateOrCreateUser({
            ...omit(
              user,
              'culture',
              'organizations',
              'passwordPolicy',
              'primaryOrganization',
              'primaryUnit',
              'primaryPointOfSale'
            ),
            cultureId: user.culture.id,
            primaryOrganizationId: user.primaryOrganization?.id,
            primaryUnitId: user.primaryUnit?.id,
            primaryPointOfSaleId: user.primaryPointOfSale?.id,
            passwordPolicyId: user.passwordPolicy?.id,
          })
        ).unwrap();
        if (data.id) {
          history.replace(`/users/edit/${data.id}`);
        }

        alert.success(
          <TransAlert i18nKey={currentUserId ? 'userUpdated' : 'userCreated'} />
        );

        dispatch(getUsers());
      }),
    [alert, currentUserId, dispatch, history]
  );
  const initialValues = useMemo(
    () => ({
      ...(currentUserId && currentUser),
    }),
    [currentUser, currentUserId]
  );
  const { form, handleSubmit, values, dirty, submitting } = useForm<User>({
    initialValues,
    onSubmit,
    subscription: { values: true, dirty: true, submitting: true },
  });
  const { primaryOrganization } = values;

  useEffect(() => {
    if (!currentUserId) {
      form.reset({});
    }
  }, [currentUser, currentUserId, form]);

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);
  const languages = useClassificationOptions(ClassificationGroup.CULTURE);

  const handleGoBack = useCallback(() => {
    history.replace('/users');
  }, [history]);
  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (event, reason) => {
      if (reason === 'close') {
        dispatch(setUser());
        handleGoBack();
      }
    },
    [dispatch, handleGoBack]
  );
  const handleResetPrimaryFields = useCallback(() => {
    form.batch(() => {
      form.change('primaryUnit', null);
      form.change('primaryPointOfSale', null);
    });
  }, [form]);
  const loading = useSelector(currentUserLoadingSelector) ?? true;
  const loaded = useLoaded(loading);

  const classes = useStyles();

  return (
    <DrawerForm open onClose={handleCloseEditForm}>
      <Loadable loading={loading}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <CardHeader
              isLight
              title={
                <Typography
                  variant="subtitle"
                  sx={{
                    flex: 1,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {currentUserId ? (
                    fullName(currentUser)
                  ) : loading ? (
                    <>&nbsp;</>
                  ) : (
                    <TransSubtitle i18nKey="newUser" />
                  )}
                </Typography>
              }
              action={
                <>
                  {currentUserId && (
                    <>
                      <UserCopyModal />
                      <UserPasswordChangeModal />
                      <UserPasswordResetModal />
                      <UserDeleteModal />
                    </>
                  )}
                  <IconButton aria-label="close" onClick={handleGoBack}>
                    <Tooltip
                      content={<TransButton i18nKey="close" />}
                      delay={500}
                    >
                      <Icon name="close" size={24} />
                    </Tooltip>
                  </IconButton>
                </>
              }
            />
            <CardContent>
              <Grid className={classes.root} container columns={4} spacing={2}>
                <Grid item xs={1}>
                  <TextField
                    name="username"
                    label={<TransField i18nKey="username" />}
                    required
                    disabled={isEditing}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="firstName"
                    label={<TransField i18nKey="firstName" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="lastName"
                    label={<TransField i18nKey="lastName" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="email"
                    label={<TransField i18nKey="email" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <DateField
                    name="validity.from"
                    label={<TransField i18nKey="validFrom" />}
                    required
                    isClearable
                  />
                </Grid>
                <Grid item xs={1}>
                  <DateField
                    name="validity.to"
                    label={<TransField i18nKey="validTo" />}
                    isClearable
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    name="culture.id"
                    label={<TransField i18nKey="language" />}
                    options={languages}
                    required
                  />
                </Grid>
                {currentUser && currentUserId && (
                  <UserFormPrimaryFields
                    currentUser={currentUser}
                    primaryOrganizationId={primaryOrganization?.id}
                    onResetPrimaryFields={handleResetPrimaryFields}
                  />
                )}
                {loggedInUser?.isPlatformUser && (
                  <Grid item xs={1}>
                    <RadioGroupField
                      name="isPlatformUser"
                      options="BOOL_ONLY"
                      inline
                      label={<TransField i18nKey="isPlatformUser" />}
                    />
                  </Grid>
                )}
                <Grid item sx={{ ml: 'auto', mt: 'auto' }}>
                  {currentUserId ? (
                    <>
                      <Button onClick={handleReset} disabled={!dirty}>
                        <TransButton i18nKey="resetChanges" />
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<Icon name="check" />}
                        disabled={submitting}
                        type="submit"
                      >
                        <TransButton i18nKey="save" />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button onClick={handleGoBack}>
                        <TransButton i18nKey="cancel" />
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<Icon name="plus" />}
                        disabled={submitting}
                        type="submit"
                      >
                        <TransButton i18nKey="create" />
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>

            {loaded && (
              <Accordion>
                <AccordionPanel
                  id="roles"
                  disabled={!currentUserId}
                  summary={
                    <Typography variant="subtitle">
                      <TransSubtitle i18nKey="roles" />
                      {currentUserId && (
                        <>
                          &nbsp;
                          <Typography component="span" variant="body2">
                            ({currentUser!.roles.length})
                          </Typography>
                        </>
                      )}
                    </Typography>
                  }
                >
                  <UserFormRoles />
                </AccordionPanel>

                <AccordionPanel
                  id="organizations"
                  disabled={!currentUserId}
                  summary={
                    <Typography variant="subtitle">
                      <TransSubtitle i18nKey="relatedOrganizations" />
                      {currentUserId && (
                        <>
                          &nbsp;
                          <Typography component="span" variant="body2">
                            ({currentUser!.organizations.length})
                          </Typography>
                        </>
                      )}
                    </Typography>
                  }
                >
                  <UserFormOrganizations />
                </AccordionPanel>
              </Accordion>
            )}
          </form>
        </FormProvider>
      </Loadable>
    </DrawerForm>
  );
};
