import { createAsyncThunk } from 'store/utils';
import {
  Application,
  BankAccountPayload,
  BookingAccessRight,
  BookingAccessRightPayload,
  ContactingOption,
  ContactingOptionPayload,
  Organization,
  OrganizationAddress,
  OrganizationAddressPayload,
  OrganizationBankAccount,
  OrganizationFile,
  OrganizationSearchFilter,
  OrganizationTicketText,
  OrganizationUnit,
  PointOfSale,
  PointsOfSalePayload,
  ServiceFeePayload,
  TicketTextPayload,
  UnitPayload,
} from 'dto/organization';
import { Stop, StopCode } from 'dto/stop';
import { selectCurrentOrganizationId } from 'features/organization/organizationSelectors';
import { Pagination } from '@fleet/shared/dto/pagination';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { api } from '@fleet/shared';

export const setOrganizationsFilter = createAction<
  Partial<OrganizationSearchFilter>
>('setOrganizationsFilter');

export const cleanOrganizationsSearch = createAction(
  'cleanOrganizationsSearch'
);

export const getOrganizationsList = createAsyncThunk<
  Pagination<Organization>,
  Partial<OrganizationSearchFilter> | undefined
>(
  'organization/getOrganizationsList',
  async (values, { dispatch, getState }) => {
    values && dispatch(setOrganizationsFilter(values));
    const { filter } = getState().organization;
    return (
      await api.get(
        `/organizations${qs.stringify(filter, {
          addQueryPrefix: true,
          skipNulls: true,
        })}`
      )
    ).data;
  }
);

export const createOrganization = createAsyncThunk<Organization, object>(
  'organization/createOrganization',
  async (payload) => (await api.post(`/organizations`, payload)).data
);

export const setCurrentOrganization = createAction<Organization | undefined>(
  'organization/setCurrentOrganization'
);

export const getOrganization = createAsyncThunk<
  Organization,
  string | undefined
>('organization/getOrganization', async (id, { getState, dispatch }) => {
  const organizationId = id ?? selectCurrentOrganizationId(getState());
  const [
    { data: organizationDetails },
    { data: bookingAccessRights },
    {
      data: { items: files },
    },
    {
      data: { items: stopCodes },
    },
  ] = await Promise.all([
    await api.get<
      Omit<Organization, 'bookingAccessRights' | 'stopCodes' | 'files'>
    >(`/organizations/${organizationId}`),
    await api.get<Array<BookingAccessRight>>(
      `/organizations/${organizationId}/retailer-interoperability-permissions`
    ),
    await api.get<{ items: Array<OrganizationFile> }>(
      `/organizations/${organizationId}/files`
    ),
    await api.get<{ items: Array<StopCode> }>(
      `/organizations/${organizationId}/codes`
    ),
  ]);
  const organization = {
    ...organizationDetails,
    bookingAccessRights,
    files,
    stopCodes,
  };
  dispatch(setCurrentOrganization(organization));
  return organization;
});

export const activateOrganization = createAsyncThunk<unknown, string>(
  'organization/activateOrganization',
  async (id, { dispatch }) => {
    await api.post<string>(`/organizations/${id}/activate`);
    dispatch(getOrganization(id));
  }
);

export const deactivateOrganization = createAsyncThunk<void, string>(
  'organization/deactivateOrganization',
  async (id, { dispatch }) => {
    await api.post<string>(`/organizations/${id}/deactivate`);
    dispatch(getOrganization(id));
  }
);

export const deactivateOrganizations = createAsyncThunk<void, Array<string>>(
  'organization/deactivateOrganizations',
  async (organizationsIds) => {
    await Promise.all(
      organizationsIds.map((id) => api.post(`/organizations/${id}/deactivate`))
    );
  }
);

export const updateOrganization = createAsyncThunk<
  Organization,
  { id: string }
>(
  'organization/updateOrganization',
  async ({ id, ...payload }) =>
    (await api.put(`/organizations/${id}`, payload)).data
);

export const deleteOrganization = createAsyncThunk<void, string>(
  'organization/deleteOrganization',
  async (id) => {
    (await api.delete(`/organizations/${id}`)).data;
  }
);

export const deleteAddress = createAsyncThunk<void, string>(
  'organization/deleteAddress',
  async (id, { getState }) => {
    const state = getState();
    (
      await api.delete(
        `/organizations/${selectCurrentOrganizationId(state)}/addresses/${id}`
      )
    ).data;
  }
);

export const getAvailableUnitsAndPointOfSales = createAsyncThunk<
  { units: Array<OrganizationUnit>; pointsOfSale: Array<PointOfSale> },
  string
>('organization/getAvailablePointOfSales', async (organizationId) => {
  const { units, pointsOfSale } = (
    await api.get(`/organizations/${organizationId}`)
  ).data;

  return { units, pointsOfSale };
});

export const getOrganizationPointOfSale = createAsyncThunk<
  void,
  {
    organizationId: string;
    id: string;
  }
>(
  'organization/getPointOfSale',
  async (id, { getState }) =>
    (
      await api.get(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/points-of-sale/${id}`
      )
    ).data
);

export const getSalesPointClient = createAsyncThunk<Array<Application>, string>(
  'organization/getPointOfSaleClientId',
  async (id) => {
    return (await api.get(`/oauth-applications/retailer?pointOfSaleId=${id}`))
      .data.items;
  }
);

interface App {
  name: string;
  clientId: string;
  pointOfSaleId: string;
}

export const createSalesPointClient = createAsyncThunk<App, object>(
  'organization/createSaleClientId',
  async (payload) =>
    (await api.post(`/oauth-applications/retailer`, payload)).data
);

export const updateOrCreateUnit = createAsyncThunk<
  OrganizationUnit,
  Partial<UnitPayload>
>(
  'organization/updateOrCreateUnit',
  async ({ id, ...payload }, { getState }) =>
    (
      await (id ? api.put : api.post)(
        `/organizations/${selectCurrentOrganizationId(getState())}/units${
          id ? `/${id}` : ''
        }`,
        { isActive: false, ...payload }
      )
    ).data
);

export const removeUnits = createAsyncThunk<
  Array<string>,
  Array<OrganizationUnit>
>('organization/removeUnits', async (units, { getState }) => {
  const removedUnitsIds = units.map(({ id }) => id);
  await Promise.all(
    removedUnitsIds.map((id) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(getState())}/units/${id}`
      )
    )
  );

  return removedUnitsIds;
});

export const updateOrCreatePointOfSale = createAsyncThunk<
  void,
  Partial<PointsOfSalePayload>
>(
  'organization/updateOrCreatePointOfSale',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/points-of-sale${id ? `/${id}` : ''}`,
      { isActive: false, ...payload }
    )
);

export const removePointOfSales = createAsyncThunk<void, Array<PointOfSale>>(
  'organization/removePointOfSales',
  async (pointsOfSales, { getState }) => {
    await Promise.all(
      pointsOfSales.map(({ id }) =>
        api.delete(
          `/organizations/${selectCurrentOrganizationId(
            getState()
          )}/points-of-sale/${id}`
        )
      )
    );
  }
);

export const updateOrCreateAddress = createAsyncThunk<
  void,
  Partial<OrganizationAddressPayload>
>(
  'organization/updateOrCreateAddress',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(getState())}/addresses${
        id ? `/${id}` : ''
      }`,
      { isActive: false, ...payload }
    )
);

export const removeAddresses = createAsyncThunk<
  void,
  Array<OrganizationAddress>
>('organization/removeAddresses', async (addresses, { getState }) => {
  await Promise.all(
    addresses.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/addresses/${id}`
      )
    )
  );
});

export const updateOrCreateContactOption = createAsyncThunk<
  void,
  Partial<ContactingOptionPayload>
>(
  'organization/updateOrCreateAddress',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/contacting-options${id ? `/${id}` : ''}`,
      payload
    )
);

export const removeContactOptions = createAsyncThunk<
  void,
  Array<ContactingOption>
>('organization/removeContactOption', async (contacts, { getState }) => {
  await Promise.all(
    contacts.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/contacting-options/${id}`
      )
    )
  );
});

export const updateOrCreateServiceFee = createAsyncThunk<
  void,
  Partial<ServiceFeePayload>
>(
  'organization/updateOrCreateServiceFee',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/retailer-service-fees${id ? `/${id}` : ''}`,
      payload
    )
);

export const removeServiceFees = createAsyncThunk<void, Array<string>>(
  'organization/removeServiceFees',
  async (ids, { getState }) => {
    await api.post(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/retailer-service-fees/bulk-delete`,
      { ids }
    );
  }
);

export const updateOrCreateBookingAccessRight = createAsyncThunk<
  void,
  Partial<BookingAccessRightPayload>
>(
  'organization/updateOrCreateBookingAccessRight',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/retailer-interoperability-permissions${id ? `/${id}` : ''}`,
      payload
    )
);

export const removeBookingAccessRights = createAsyncThunk<
  void,
  Array<BookingAccessRight>
>('organization/removeBookingAccessRights', async (rights, { getState }) => {
  await Promise.all(
    rights.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/retailer-interoperability-permissions/${id}`
      )
    )
  );
});

export const createOrganizationStopCode = createAsyncThunk<
  Stop,
  { codeListId: string; code: string }
>(
  'organization/createOrganizationStopCode',
  async ({ codeListId, code }, { getState }) =>
    (
      await api.put(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/codes/${codeListId}`,
        { code }
      )
    ).data
);

export const removeOrganizationStopCodes = createAsyncThunk<
  void,
  Array<StopCode>
>('organization/deleteStopCodes', async (ids, { getState }) => {
  await Promise.all(
    ids.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(getState())}/codes/${id}`
      )
    )
  );
});

export const updateOrCreateBankAccount = createAsyncThunk<
  void,
  Partial<BankAccountPayload>
>(
  'organization/updateOrCreateBankAccount',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(getState())}/bank-account${
        id ? `/${id}` : ''
      }`,
      payload
    )
);

export const removeBankAccounts = createAsyncThunk<
  void,
  Array<OrganizationBankAccount>
>('organization/removeBankAccounts', async (bankAccounts, { getState }) => {
  await Promise.all(
    bankAccounts.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/bank-account/${id}`
      )
    )
  );
});

export const updateOrCreateTicketText = createAsyncThunk<
  void,
  Partial<TicketTextPayload>
>(
  'organization/updateOrCreateTicketText',
  async ({ id, ...payload }, { getState }) =>
    await (id ? api.put : api.post)(
      `/organizations/${selectCurrentOrganizationId(getState())}/ticket-text${
        id ? `/${id}` : ''
      }`,
      payload
    )
);

export const removeTicketTexts = createAsyncThunk<
  void,
  Array<OrganizationTicketText>
>('organization/removeTicketTexts', async (ticketTexts, { getState }) => {
  await Promise.all(
    ticketTexts.map(({ id }) =>
      api.delete(
        `/organizations/${selectCurrentOrganizationId(
          getState()
        )}/ticket-text/${id}`
      )
    )
  );
});

export const deleteOrganizationFiles = createAsyncThunk<void, Array<string>>(
  'organization/deleteFiles',
  async (fileEntityIds, { getState }) =>
    await api.post(
      `/organizations/${selectCurrentOrganizationId(
        getState()
      )}/files/bulk-delete`,
      fileEntityIds
    )
);

export const updateOrCreateOrganizationFile = createAsyncThunk<
  OrganizationFile,
  { payload: FormData; fileEntityId?: string }
>(
  'organization/updateOrCreateOrganizationFile',
  async ({ fileEntityId, payload }, { getState }) =>
    (
      await (fileEntityId ? api.put : api.post)(
        `/organizations/${selectCurrentOrganizationId(getState())}/files${
          fileEntityId ? `/${fileEntityId}` : ''
        }`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    ).data
);
