import { api } from '@fleet/shared';
import qs from 'qs';
import { Classifier } from '@fleet/shared/dto/classifier';

export const fetchOrganizations = async () =>
  (
    await api.get<{ items: Array<Classifier> }>(
      `/organizations?${qs.stringify({
        limit: 1000,
      })}`
    )
  ).data.items;
