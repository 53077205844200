import { createAsyncThunk } from 'store/utils';
import { Alliance, Localization, Logo } from 'dto/alliance';
import { createAction } from '@reduxjs/toolkit';
import { selectCurrentAllianceId } from 'features/alliance/allianceSelectors';
import { isUndefined } from 'lodash';
import { api } from '@fleet/shared';

export const clearAllianceList = createAction('alliance/clearAllianceList');

export const getAlliancesList = createAsyncThunk<Array<Alliance>>(
  'alliance/getAlliances',
  async () => (await api.get(`/alliances`)).data.items ?? [] // temp fallback so UI won't crash, as empty response is returned
);

export const clearAlliance = createAction('alliance/clearAlliance');

export const setAlliance = createAction<Alliance | undefined>(
  'alliance/setAlliance'
);

export const getAlliance = createAsyncThunk<Alliance, string>(
  'alliance/getAlliance',
  async (id, store) => {
    const alliance = (await api.get(`/alliances/${id}`)).data;
    store.dispatch(setAlliance(alliance));
    return alliance;
  }
);

export const createAlliance = createAsyncThunk<Alliance, Partial<Alliance>>(
  'alliance/createAlliance',
  async (payload) => (await api.post('/alliances', payload)).data
);

export const updateAlliance = createAsyncThunk<Alliance, Alliance>(
  'alliance/updateAlliance',
  async ({ id, ...payload }) =>
    (await api.put(`/alliances/${id}`, payload)).data
);

export const deleteAlliance = createAsyncThunk<void, string>(
  'alliance/deleteAlliance',
  async (id) => await api.delete(`/alliances/${id}`)
);

export const deleteAlliances = createAsyncThunk<void, Array<string>>(
  'organization/deleteAlliances',
  async (allianceIds) => {
    await Promise.all(allianceIds.map((id) => api.delete(`/alliances/${id}`)));
  }
);

export const assignAllianceOrganization = createAsyncThunk<
  void,
  { allianceId: string | number; organizationId: string }
>(
  'alliance/assignAllianceOrganization',
  async ({ allianceId, organizationId }) =>
    await api.put(
      `/alliances/${allianceId}/organizations/${organizationId}`,
      {}
    )
);

export const deleteAllianceOrganization = createAsyncThunk<
  void,
  { allianceId: string | number; organizationId: string }
>(
  'alliance/deleteAllianceOrganization',
  async ({ allianceId, organizationId }) =>
    await api.delete(`/alliances/${allianceId}/organizations/${organizationId}`)
);

export const createAllianceLocalization = createAsyncThunk<
  void,
  Omit<Localization, 'id'>
>(
  'alliance/createAllianceLocalization',
  async ({ logo, ...payload }, { getState, dispatch }) => {
    const allianceId = selectCurrentAllianceId(getState());

    const { id } = (
      await api.post(`/alliances/${allianceId}/localizations`, payload)
    ).data;
    await dispatch(uploadLocalizationFile({ id: id, logo: logo }));

    dispatch(getAlliance(allianceId));
  }
);

export const updateAllianceLocalization = createAsyncThunk<void, Localization>(
  'alliance/createAllianceLocalization',
  async ({ id, logo, ...payload }, { getState, dispatch }) => {
    const allianceId = selectCurrentAllianceId(getState());

    await api.put(
      `/alliances/${selectCurrentAllianceId(getState())}/localizations/${id}`,
      payload
    );
    await dispatch(uploadLocalizationFile({ id, logo }));

    dispatch(getAlliance(allianceId));
  }
);

export const deleteAllianceLocalization = createAsyncThunk<void, string>(
  'alliance/deleteAllianceLocalization',
  async (id, { getState }) => {
    const state = getState();
    (
      await api.delete(
        `/alliances/${selectCurrentAllianceId(state)}/localizations/${id}`
      )
    ).data;
  }
);

export const uploadLocalizationFile = createAsyncThunk<
  void,
  { id: string; logo?: Logo | null }
>('alliance/uploadLocalizationFile', async ({ id, logo }, { getState }) => {
  const allianceId = selectCurrentAllianceId(getState());

  if (isUndefined(logo)) {
    await api.delete(`/alliances/${allianceId}/localizations/${id}/logo`);
  } else if (logo?.size) {
    const { name, type } = logo;
    await api.put(
      `/alliances/${allianceId}/localizations/${id}/logo?Name=${name}&ContentType=${type}`,
      logo,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      }
    );
  }
});
