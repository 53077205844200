import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  addRelatedOrganizations: (
    <Trans
      i18nKey="modal.title.addRelatedOrganizations"
      defaults="Add related organizations"
    />
  ),
  allianceDeletionDescription: (
    <Trans
      i18nKey="modal.title.allianceDeletionDescription"
      defaults="Are you sure you want to delete alliance {{name}}? You can't undo this"
    />
  ),
  auditExecutionState: (
    <Trans
      i18nKey="modal.title.auditExecutionState"
      defaults="State after execution"
    />
  ),
  bookingFeeDeletionDescription: (
    <Trans
      i18nKey="modal.title.bookingFeesDeletionDescription"
      defaults="Are you sure you want to delete this booking fee? You can't undo this"
    />
  ),
  bookingFeesDeletionDescription: (
    <Trans
      i18nKey="modal.title.bookingFeesDeletionDescription"
      defaults="Are you sure you want to delete selected booking fees? You can't undo this"
    />
  ),
  cardTypeDeletionDescription: (
    <Trans
      i18nKey="modal.title.cardTypeDeletionDescription"
      defaults="Are you sure you want to delete card type {{name}}? You can't undo this"
    />
  ),
  changePassword: (
    <Trans i18nKey="modal.title.changePassword" defaults="Change password" />
  ),
  copyUser: <Trans i18nKey="modal.title.copyUser" defaults="Copy user" />,
  deleteAlliance: (
    <Trans i18nKey="modal.title.deleteAlliance" defaults="Delete alliance" />
  ),
  deleteBookingFee: (
    <Trans
      i18nKey="modal.title.deleteBookingFee"
      defaults="Delete booking fee"
    />
  ),
  deleteBookingFees: (
    <Trans
      i18nKey="modal.title.deleteBookingFees"
      defaults="Delete booking fees"
    />
  ),
  deleteCardType: (
    <Trans i18nKey="modal.title.deleteCardType" defaults="Delete card type" />
  ),
  deleteCodeList: (
    <Trans
      i18nKey="modal.title.deleteCodeList"
      defaults="Delete stop code list"
    />
  ),
  deleteOrganization: (
    <Trans
      i18nKey="modal.title.deleteOrganization"
      defaults="Delete organization"
    />
  ),
  deleteUser: <Trans i18nKey="modal.title.deleteUser" defaults="Delete user" />,
  organizationDeletionDescription: (
    <Trans
      i18nKey="modal.title.organizationDeletionDescription"
      defaults="Are you sure you want to delete organization {{name}}? You can't undo this"
    />
  ),
  userDeletionDescription: (
    <Trans
      i18nKey="modal.title.userDeletionDescription"
      defaults="Are you sure you want to delete user {{name}}? You can't undo this"
    />
  ),
});
