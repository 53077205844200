import { getCurrentUser } from 'features/common/commonActions';
import { getClassifications } from 'features/classification/classificationActions';
import { getAuditTrail, getAuditTrailState } from 'features/audit/auditActions';
import { getStopsList } from 'features/stop/stopActions';
import { createLoadingSelector } from 'store/utils';
import {
  assignUserOrganization,
  assignUserOrganizationPointOfSale,
  assignUserOrganizationUnit,
  assignUserRole,
  deleteUserOrganization,
  deleteUserOrganizationPointOfSale,
  deleteUserOrganizationUnit,
  deleteUserRole,
  getUser,
  getUsers,
} from 'features/user/userActions';
import {
  activateOrganization,
  createOrganization,
  createSalesPointClient,
  deactivateOrganization,
  deleteOrganizationFiles,
  getOrganization,
  getOrganizationsList,
  getSalesPointClient,
  removeUnits,
  updateOrCreateOrganizationFile,
  updateOrganization,
} from 'features/organization/organizationActions';

import {
  assignAllianceOrganization,
  createAlliance,
  deleteAlliance,
  deleteAllianceOrganization,
  getAlliance,
  getAlliancesList,
  updateAlliance,
} from 'features/alliance/allianceActions';

import {
  createImsRegister,
  getImsRegisterById,
  getImsRegisters,
  updateImsRegister,
} from 'features/imsRegister/imsRegisterActions';
import {
  createCodeList,
  deleteCodeList,
  getCodeListById,
  getCodeLists,
  updateCodeList,
} from 'features/codeList/codeListActions';
import { getBaseDataFile } from 'features/files/fileActions';
import {
  createOrUpdateCardType,
  deleteCardType,
  getCardType,
  getCardTypes,
} from 'features/cardType/cardTypeActions';
import {
  createBookingFee,
  deleteBookingFees,
  getBookingFee,
  getBookingFees,
  updateBookingFee,
} from 'features/bookingFee/bookingFeeActions';

export const appLoadingSelector = createLoadingSelector(
  getCurrentUser,
  getClassifications
);

export const auditLoadingSelector = createLoadingSelector(
  getAuditTrail,
  getAuditTrailState
);
export const usersLoadingSelector = createLoadingSelector(getUsers);

export const imsLoadingSelector = createLoadingSelector(
  getImsRegisters,
  createImsRegister,
  updateImsRegister,
  getImsRegisterById
);

export const cardTypesLoadingSelector = createLoadingSelector(
  getCardType,
  getCardTypes,
  createOrUpdateCardType,
  deleteCardType
);

export const codeListsLoadingSelector = createLoadingSelector(
  getCodeLists,
  getCodeListById,
  updateCodeList,
  createCodeList,
  deleteCodeList
);
export const currentUserLoadingSelector = createLoadingSelector(
  getUser,
  assignUserRole,
  deleteUserRole,
  deleteUserOrganization,
  assignUserOrganizationUnit,
  deleteUserOrganizationUnit,
  assignUserOrganizationPointOfSale,
  deleteUserOrganizationPointOfSale
);
export const userAssignOrganizationLoadingSelector = createLoadingSelector(
  assignUserOrganization,
  deleteUserOrganization
);

export const organizationsListLoadingSelector =
  createLoadingSelector(getOrganizationsList);

export const organizationsFormLoadingSelector = createLoadingSelector(
  activateOrganization,
  updateOrganization,
  deactivateOrganization,
  createOrganization,
  getOrganization,
  removeUnits
);

export const salesPointClientIdModalLoadingSelector = createLoadingSelector(
  getSalesPointClient,
  createSalesPointClient
);

export const stopListLoadingSelector = createLoadingSelector(getStopsList);

export const alliancesListLoadingSelector =
  createLoadingSelector(getAlliancesList);

export const alliancesLoadingSelector = createLoadingSelector(
  getAlliance,
  updateAlliance,
  createAlliance,
  deleteAlliance,
  deleteAllianceOrganization,
  assignAllianceOrganization
);

export const filesLoadingSelector = createLoadingSelector(
  getBaseDataFile,
  updateOrCreateOrganizationFile,
  deleteOrganizationFiles
);

export const bookingFeesLoadingSelector = createLoadingSelector(
  getBookingFees,
  deleteBookingFees,
  getBookingFee
);

export const bookingFeeLoadingSelector = createLoadingSelector(
  getBookingFee,
  createBookingFee,
  updateBookingFee,
  deleteBookingFees
);
