import { FC, useCallback, useMemo } from 'react';
import {
  Button,
  CardContent,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import {
  SearchResult,
  Table,
  TableColumns,
  useTableRowHighlight,
} from '@fleet/shared';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { useRowActive } from '@fleet/shared/hooks/useRowActive';
import { useHistory, useParams } from 'react-router-dom';
import { Stop } from 'dto/stop';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import {
  stopsFilterSelector,
  stopsSelector,
} from 'features/stop/stopSelectors';
import { StopsSearchForm } from 'routes/stops/StopsSearchForm';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { deleteStops, getStopsList } from 'features/stop/stopActions';
import { PaginationParams } from '@fleet/shared/dto/pagination';

interface StopsTableProps {}

export const StopsTable: FC<StopsTableProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stops = useSelector(stopsSelector);
  const filter = useSelector(stopsFilterSelector);
  const { id } = useParams<{ action: 'create' | 'edit'; id?: string }>();
  const data = useMemo(() => stops?.items ?? [], [stops]);

  const initialState = useMemo(
    () => ({ pageSize: 10, activeRowId: id, filter }),
    [id, filter]
  );

  const getPage = useCallback(
    (pageSize: number) => {
      if (stops) {
        const { limit = pageSize, offset } = stops;
        return offset / limit;
      }
      return 0;
    },
    [stops]
  );

  const link = useCallback(
    (row: Row<Stop>) => `/stops/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<Stop>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="stopName" />,
        Cell: ({ row }) => (
          <MuiLink underline="none">{row.original.name}</MuiLink>
        ),
      },
      {
        id: 'address',
        accessor: (row) => row.address.country.name,
        Header: <TransTableHead i18nKey="country" />,
      },
      {
        id: 'county',
        accessor: (row) => row.address.county,
        Header: <TransTableHead i18nKey="county" />,
      },
      {
        id: 'city',
        accessor: (row) => row.address.city,
        Header: <TransTableHead i18nKey="cityParish" />,
      },
      {
        id: 'street',
        accessor: (row) => row.address.street,
        Header: <TransTableHead i18nKey="streetHouseNumber" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getStopsList({ ...filter, ...paginationParams })).unwrap(),
    [dispatch, filter]
  );

  const getRowId = useCallback((row: Stop) => row.id, []);

  const table = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: stops?.totalCount,
      getRowId,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useRowSelectCheckbox
  );
  const {
    state: { selectedRowIds },
  } = table;

  const selectedStopIds = useMemo(
    () => Object.keys(selectedRowIds),
    [selectedRowIds]
  );

  const removeSelectedStops = useCallback(async () => {
    await dispatch(deleteStops(selectedStopIds));
    dispatch(getStopsList());
  }, [dispatch, selectedStopIds]);

  useTableRowHighlight(id, table);

  return (
    <>
      <StopsSearchForm />
      <Divider />
      <SearchResult results={!!data.length}>
        <Table
          caption={
            <CardContent>
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle" fontWeight="700">
                  <TransSubtitle i18nKey="searchResults" />
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 2 }}
                >
                  <TransSubtitle
                    i18nKey="stopQty"
                    values={{ num: stops?.totalCount }}
                  />
                </Typography>
                {!!selectedStopIds.length && (
                  <>
                    <Button
                      startIcon={<Icon name="trash" />}
                      sx={{ ml: 'auto', px: 1, py: 0 }}
                      onClick={removeSelectedStops}
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                  </>
                )}
              </Stack>
            </CardContent>
          }
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </>
  );
};
