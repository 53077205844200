import { FC, useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  FormProvider,
  useForm,
  Tooltip,
  CardHeader,
  formSubmit,
} from '@fleet/shared';
import { FormField } from '@fleet/shared/form/FormField';
import {
  SelectField,
  TextField,
  TimeZoneField,
  RadioGroupField,
} from '@fleet/shared/form';
import {
  Button,
  CardContent,
  Grid,
  Stack,
  Typography,
  Divider,
  IconButton,
} from '@mui/material';
import { Icon } from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { Accordion, AccordionPanel } from '@fleet/shared/mui';
import { StopLocalizationResponse, StopPayload } from 'dto/stop';
import {
  getStop,
  createStop,
  updateStop,
  deleteStop,
  getStopsList,
  clearStop,
} from 'features/stop/stopActions';
import { StopLocalizationsTable } from 'routes/stops/StopLocalizationsTable';
import { currentStopSelector } from 'features/stop/stopSelectors';
import { useHistory } from 'react-router-dom';
import { TransAlert } from 'i18n/trans/alert';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useAlert } from 'react-alert';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { StopFormCode } from 'routes/stops/StopFormCodes';
const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'StopForm',
  }
);

export interface StopFormProps {
  id?: string;
}

export const StopForm: FC<StopFormProps> = ({ id }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const currentStop = useSelector(currentStopSelector);
  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);
  const initialValues = useMemo(
    () =>
      currentStop && {
        ...currentStop,
        address: {
          ...currentStop.address,
          countryId: currentStop.address.country.id,
        },
      },
    [currentStop]
  );
  const onSubmit = useCallback(
    (values) =>
      formSubmit(async () => {
        const v = {
          ...values,
          isSearchable: values.isSearchable == 'true',
        };
        const stop = await dispatch(
          id ? updateStop(values) : createStop(v)
        ).unwrap();

        if (stop.id) {
          history.replace(`/stops/edit/${stop.id}`);
        }

        alert.success(<TransAlert i18nKey={id ? 'stopUpdate' : 'stopAdd'} />);

        dispatch(getStopsList());
      }),
    [alert, history, dispatch, id]
  );

  const { form, handleSubmit, pristine } = useForm<StopPayload>({
    initialValues,
    onSubmit,
  });
  const { reset, restart } = form;

  const onStopDelete = useCallback(async () => {
    await dispatch(deleteStop(id!));
    alert.success(<TransAlert i18nKey="stopDelete" />);
    history.replace('/stops');
    await dispatch(getStopsList());
  }, [alert, id, history, dispatch]);

  useEffect(() => {
    restart(currentStop ? initialValues : {});
  }, [currentStop, initialValues, restart]);

  useEffect(() => {
    if (id) {
      dispatch(getStop(id));
    } else {
      dispatch(clearStop());
    }
  }, [dispatch, id]);

  const handleReset = useCallback(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const handleGoBack = useCallback(() => {
    history.replace('/stops');
  }, [history]);

  return (
    <FormProvider {...form}>
      <CardHeader
        isLight
        title={
          <Typography variant="subtitle">
            {id ? currentStop?.name : <TransSubtitle i18nKey="newStop" />}
          </Typography>
        }
        action={
          <>
            {id && (
              <Button startIcon={<Icon name="trash" />} onClick={onStopDelete}>
                <TransButton i18nKey="delete" />
              </Button>
            )}
            <IconButton aria-label="close" onClick={handleGoBack}>
              <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                <Icon name="close" size={24} />
              </Tooltip>
            </IconButton>
          </>
        }
      />
      <CardContent
        sx={{ p: 3, pt: 2 }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid className={classes.root} container columns={4} spacing={2}>
          <Grid item xs={1}>
            <TextField
              name="name"
              label={<TransField i18nKey="name" />}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="address.countryId"
              label={<TransField i18nKey="country" />}
              options={countryOptions}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <TimeZoneField
              name="timezone"
              label={<TransField i18nKey="timeZone" />}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="address.county"
              label={<TransField i18nKey="county" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="address.city"
              label={<TransField i18nKey="cityParish" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="address.street"
              label={<TransField i18nKey="street" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="address.zipCode"
              label={<TransField i18nKey="zipCode" />}
            />
          </Grid>
          <Grid item xs={1}>
            <RadioGroupField
              name="isWheelChairAccessible"
              options="BOOL_ONLY"
              inline
              label={<TransField i18nKey="wheelchair" />}
            />
          </Grid>
          <Grid item xs={1}>
            <RadioGroupField
              name="isMultilegHub"
              options="BOOL_ONLY"
              inline
              label={<TransField i18nKey="multileg" />}
            />
          </Grid>
          <Grid item xs={1}>
            <RadioGroupField
              name="isSearchable"
              options="BOOL_ONLY"
              inline
              label={
                <>
                  <TransField i18nKey="searchable" />
                  <Tooltip
                    delay={10}
                    content={<TransButton i18nKey="searchable" />}
                    placement="top"
                  >
                    <Button sx={{ p: 0, minWidth: 25, color: '#293845' }}>
                      <Icon name="question" size={13} />
                    </Button>
                  </Tooltip>
                </>
              }
            />
          </Grid>
        </Grid>

        <Divider sx={{ margin: '18px 0' }} />
        <Grid className={classes.root} container columns={4} spacing={2}>
          <Grid item xs={1}>
            <TextField
              name="coordinates.latitude"
              label={<TransField i18nKey="latitude" />}
              type="number"
            />
          </Grid>

          <Grid item xs={1}>
            <TextField
              name="coordinates.longitude"
              label={<TransField i18nKey="longitude" />}
              type="number"
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {!id ? (
            <Button onClick={() => history.replace('/stops')}>
              <TransButton i18nKey="cancel" />
            </Button>
          ) : (
            <Button disabled={pristine} onClick={handleReset}>
              <TransButton i18nKey="resetChanges" />
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            startIcon={<Icon name="plus24" />}
          >
            {id ? (
              <TransButton i18nKey="save" />
            ) : (
              <TransButton i18nKey="create" />
            )}
          </Button>
        </Stack>
      </CardContent>
      <Accordion>
        <AccordionPanel
          id="langDependantParams"
          summary={
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="langDependantParams" />
              &nbsp;
              <Typography component="span" variant="body1">
                <FormField<Array<StopLocalizationResponse>>
                  name="localizations"
                  subscription={{ value: true }}
                >
                  {({ input: { value } }) => `(${value.length})`}
                </FormField>
              </Typography>
            </Typography>
          }
          disabled={!id}
        >
          {currentStop && <StopLocalizationsTable stopId={currentStop!.id} />}
        </AccordionPanel>
        <AccordionPanel
          id="codes"
          disabled={!currentStop}
          summary={
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="codes" />

              {currentStop && (
                <>
                  &nbsp;
                  <Typography component="span" variant="body2">
                    ({currentStop!.codes.length})
                  </Typography>
                </>
              )}
            </Typography>
          }
        >
          {currentStop && (
            <StopFormCode data={currentStop!.codes} stopId={currentStop!.id} />
          )}
        </AccordionPanel>
      </Accordion>
    </FormProvider>
  );
};
