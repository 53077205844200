import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import {
  Typography,
  CardContent,
  Divider,
  Link as MuiLink,
  Stack,
} from '@mui/material';
import { UsersSearchForm } from 'routes/users/UsersSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import {
  usersFilterSelector,
  usersSelector,
} from 'features/user/userSelectors';
import { Table, TableColumns, useTableRowHighlight } from '@fleet/shared';
import { useTable, usePagination, Row } from 'react-table';
import { User } from 'dto/user';
import { useRowActive } from '@fleet/shared/hooks/useRowActive';
import { useHistory, useParams } from 'react-router-dom';
import { fullName } from 'helpers/user';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { makeStyles } from '@mui/styles';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { getUsers } from 'features/user/userActions';
import { TransTableHead } from 'i18n/trans/table';

interface UsersTableProps {}

const useStyles = makeStyles(
  () => ({
    tableCell: {
      '&:first-child': {
        paddingLeft: 24,
      },
    },
  }),
  {
    name: 'UsersTable',
  }
);

export const UsersTable: FC<UsersTableProps> = () => {
  const classes = useStyles();
  const users = useSelector(usersSelector);
  const filter = useSelector(usersFilterSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const getPage = useCallback(
    (pageSize: number) => {
      if (users) {
        const { limit = pageSize, offset } = users;
        return offset / limit;
      }
      return 0;
    },
    [users]
  );
  const data = useMemo(() => users?.items ?? [], [users]);
  const { id } = useParams<{ id?: string }>();

  const initialState = useMemo(() => ({ pageSize: 10, activeRowId: id }), [id]);

  const link = useCallback(
    (row: Row<User>) => `/users/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<User>>(
    () => [
      {
        accessor: 'username',
        Header: <TransTableHead i18nKey="username" />,
        Cell: ({ row }) => (
          <MuiLink underline="none">{row.original.username}</MuiLink>
        ),
        width: '45%',
      },
      {
        accessor: 'firstName',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row }) => fullName(row.original),
        width: '25%',
      },
      {
        accessor: 'organizations',
        Header: <TransTableHead i18nKey="organizations" />,
        Cell: ({ value }) => value.map(({ name }) => name).join(' ,'),
        width: '30%',
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getUsers({ ...filter, ...paginationParams })).unwrap(),
    [dispatch, filter]
  );

  const getRowId = useCallback((row: User) => row.id, []);

  const table = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: users?.totalCount,
      getRowId,
    },
    usePagination,
    useRowActive
  );

  useTableRowHighlight(id, table);

  return (
    <Table
      classes={{
        cell: classes.tableCell,
      }}
      caption={
        <>
          <UsersSearchForm />
          <Divider />
          <CardContent>
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle" fontWeight="700">
                <TransSubtitle i18nKey="searchResults" />
              </Typography>

              {Boolean(users?.totalCount) && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 2 }}
                >
                  <TransSubtitle
                    i18nKey="usersQty"
                    values={{ num: users!.totalCount }}
                  />
                </Typography>
              )}
            </Stack>
          </CardContent>
        </>
      }
      table={table}
      getRowProps={(_, { row }) => ({
        sx: { cursor: 'pointer' },
        onClick: () => history.push(link(row)),
      })}
    />
  );
};
