import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { CardHeader } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { imsLoadingSelector } from 'features/loading/loadingSelectors';
import { CardContent, Grid } from '@mui/material';
import { Layout } from '@fleet/shared/components/Layout';
import { AddButton, Loadable, SearchCard, SearchResult } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { imsRegistersSelector } from 'features/imsRegister/imsRegisterSelectors';
import { getImsRegisters } from 'features/imsRegister/imsRegisterActions';
import { ImsRegisterForm } from 'routes/configuration/imsRegisters/ImsRegisterForm';

interface ImsRegistersProps {}

export const ImsRegisters: FC<ImsRegistersProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(imsLoadingSelector);
  const imsRegisters = useSelector(imsRegistersSelector);

  useEffect(() => {
    dispatch(getImsRegisters());
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransTitle i18nKey="imsRegisters" />}>
            <AddButton
              onClick={() =>
                history.push('/configuration/ims-registers/create')
              }
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/configuration/ims-registers/:action(create|edit)/:id?"
              component={ImsRegisterForm}
              exact
            />
          </Switch>
        }
      >
        <SearchResult
          results={imsRegisters.length}
          i18nKey="noDataAdded"
          loading={loading}
        >
          <CardContent sx={{ p: 2, flexGrow: 1 }}>
            <Grid container spacing={3}>
              {imsRegisters.map(({ id, name }) => (
                <Grid key={id} item sm={4} md={3}>
                  <SearchCard
                    title={name}
                    list={[]}
                    {...{
                      component: Link,
                      to: `/configuration/ims-registers/edit/${id}`,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </SearchResult>
      </Layout>
    </Loadable>
  );
};
