import { FC, useCallback, useEffect } from 'react';
import {
  Button,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Icon,
  FormProvider,
  useForm,
  Loadable,
  SelectField,
  useModal,
  ConfirmDeleteModal,
  CardHeader,
  formSubmit,
} from '@fleet/shared';
import { TextField } from '@fleet/shared/form';
import { Accordion, AccordionPanel, Tooltip } from '@fleet/shared/mui';
import { Alliance } from 'dto/alliance';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import {
  clearAlliance,
  createAlliance,
  deleteAlliance,
  getAlliance,
  getAlliancesList,
  updateAlliance,
} from 'features/alliance/allianceActions';
import { alliancesSelector } from 'features/alliance/allianceSelectors';
import { AllianceMembersTable } from 'routes/alliances/AllianceMembersTable';
import { alliancesLoadingSelector } from 'features/loading/loadingSelectors';
import { TransField } from 'i18n/trans/field';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { AllianceLocalizationsTable } from 'routes/alliances/AllianceLocalizationsTable';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { TransSubtitle } from 'i18n/trans/subtitle';

export interface AlliancesFormProps {}

export const AlliancesForm: FC<AlliancesFormProps> = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(alliancesLoadingSelector);
  const alert = useAlert();
  const countries = useClassificationOptions(ClassificationGroup.COUNTRY);
  const { open: isOpen, onOpen, onClose } = useModal();

  useEffect(() => {
    if (id) {
      dispatch(getAlliance(id));
    } else {
      dispatch(clearAlliance());
    }
  }, [dispatch, id]);

  const currentAlliance = useSelector(alliancesSelector);

  const onSubmit = useCallback(
    (data: Alliance) =>
      formSubmit(async () => {
        const parsedData = {
          ...data,
          priority: +data.priority,
          regionalRestrictions: data.regionalCountryRestrictions!.map(
            (country) => ({
              country: { id: country },
            })
          ),
        };

        const alliance = await dispatch(
          id ? updateAlliance(parsedData) : createAlliance(parsedData)
        ).unwrap();

        if (alliance.id) {
          history.replace(`/alliances/edit/${alliance.id}`);
        }

        alert.success(
          <TransAlert i18nKey={id ? 'allianceUpdated' : 'allianceAdd'} />
        );

        dispatch(getAlliancesList());
      }),
    [alert, dispatch, history, id]
  );

  const { form, handleSubmit } = useForm<Alliance>({
    initialValues: {
      ...currentAlliance,
      regionalCountryRestrictions: currentAlliance?.regionalRestrictions.map(
        ({ country: { id } }) => id
      ),
    },
    onSubmit,
  });

  const handleGoBack = useCallback(() => {
    history.replace('/alliances');
  }, [history]);

  const handleDelete = useCallback(async () => {
    await dispatch(deleteAlliance(id)).unwrap();
    dispatch(getAlliancesList());
    alert.success(<TransAlert i18nKey="allianceDeleted" />);
    history.replace('/alliances');
  }, [alert, dispatch, history, id]);

  return (
    <Loadable loading={loading}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <CardHeader
            isLight
            title={
              <Typography variant="subtitle" sx={{ flex: 1 }}>
                {id ? (
                  currentAlliance?.name
                ) : (
                  <TransSubtitle i18nKey="newAlliance" />
                )}
              </Typography>
            }
            action={
              <>
                {id && currentAlliance && (
                  <>
                    <Button
                      onClick={onOpen}
                      startIcon={<Icon name="trash" />}
                      variant="text"
                      sx={{ fontSize: '12px', p: '6px 0 6px 16px', mr: 1 }}
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                    <ConfirmDeleteModal
                      handleDelete={handleDelete}
                      title={<TransModal i18nKey="deleteAlliance" />}
                      description={
                        <TransModal
                          i18nKey="allianceDeletionDescription"
                          values={{ name: currentAlliance.name }}
                        />
                      }
                      isOpen={isOpen}
                      onClose={onClose}
                    />
                  </>
                )}
                <IconButton aria-label="close" onClick={handleGoBack}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              </>
            }
          />
          <CardContent>
            <Grid container columns={4} spacing={2}>
              <Grid item xs={1}>
                <TextField
                  name="name"
                  label={<TransField i18nKey="name" />}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="code"
                  label={<TransField i18nKey="code" />}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="regionalCountryRestrictions"
                  label={<TransField i18nKey="regionalRestrictions" />}
                  options={countries}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="priority"
                  type="number"
                  label={<TransField i18nKey="priority" />}
                  required
                />
              </Grid>
              <Grid item sx={{ ml: 'auto' }}>
                <Button onClick={handleGoBack}>
                  <TransButton i18nKey="cancel" />
                </Button>
                {id ? (
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => handleSubmit}
                    startIcon={<Icon name="check" />}
                  >
                    <TransButton i18nKey="save" />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => handleSubmit}
                    startIcon={<Icon name="plus24" />}
                  >
                    <TransButton i18nKey="create" />
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </form>

        <Accordion>
          <AccordionPanel
            id="members"
            summary={
              <Typography variant="subtitle">
                <TransSubtitle i18nKey="members" />
                &nbsp;
                <Typography component="span" variant="body2">
                  {`(${currentAlliance?.organizations.length ?? 0})`}
                </Typography>
              </Typography>
            }
            disabled={!id}
          >
            <AllianceMembersTable data={currentAlliance?.organizations} />
          </AccordionPanel>
          <AccordionPanel
            id="termsAndConditions"
            summary={
              <Typography variant="subtitle">
                <TransSubtitle i18nKey="termsAndConditions" />
                &nbsp;
                <Typography component="span" variant="body2">
                  {`(${currentAlliance?.localizations.length ?? 0})`}
                </Typography>
              </Typography>
            }
            disabled={!id}
          >
            <AllianceLocalizationsTable
              data={currentAlliance?.localizations ?? []}
            />
          </AccordionPanel>
        </Accordion>
      </FormProvider>
    </Loadable>
  );
};
