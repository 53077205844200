import type { FC } from 'react';
import {
  makeClassificationOptions,
  SelectField,
  SelectFieldProps,
} from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { useSelector } from 'store/utils';
import { loggedInUserSelector } from 'features/common/commonSelectors';
import { useMemo } from 'react';

interface OwnerFieldProps extends Partial<SelectFieldProps<string, false>> {}

export const OwnerField: FC<OwnerFieldProps> = (props: OwnerFieldProps) => {
  const { organizations: businessEntities = [] } =
    useSelector(loggedInUserSelector);

  const businessEntityOptions = useMemo(
    () => makeClassificationOptions(businessEntities),
    [businessEntities]
  );

  return (
    <SelectField
      name="ownerId"
      label={<TransField i18nKey="owner" />}
      options={businessEntityOptions}
      required
      {...props}
    />
  );
};
