import { createAsyncThunk } from 'store/utils';
import { ImsRegister } from 'dto/imsRegister';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { api } from '@fleet/shared';

export const getImsRegisters = createAsyncThunk<Array<ImsRegister>>(
  'imsRegister/getImsConfigurations',
  async () =>
    (
      await api.get(
        `/ims-configurations?${qs.stringify({
          limit: 1000,
          offset: 0,
        })}`
      )
    ).data.items
);

export const createImsRegister = createAsyncThunk<ImsRegister, object>(
  'imsRegister/createImsRegister',
  async (payload) => (await api.post(`/ims-configurations`, payload)).data
);

export const updateImsRegister = createAsyncThunk<ImsRegister, ImsRegister>(
  'imsRegister/updateImsRegister',
  async ({ id, ...payload }) =>
    (await api.put(`ims-configurations/${id}`, payload)).data
);

export const setImsRegister = createAction<ImsRegister | undefined>(
  'imsRegister/setImsRegister'
);

export const getImsRegisterById = createAsyncThunk<ImsRegister, string>(
  'imsRegister/getImsRegisterById',
  async (id, store) => {
    const ims = (await api.get(`/ims-configurations/${id}`)).data;
    store.dispatch(setImsRegister(ims));
    return ims;
  }
);
