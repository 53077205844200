import { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { appLoadingSelector } from 'features/loading/loadingSelectors';
import {
  currentBusinessEntityIdSelector,
  loggedInUserSelector,
} from 'features/common/commonSelectors';
import { getCurrentUser } from 'features/common/commonActions';
import { getClassifications } from 'features/classification/classificationActions';
import { Header } from 'components/Header';
import { useAuth } from 'react-oidc-context';
import { Audit } from 'routes/Audit';
import { Users } from 'routes/Users';
import { Alliances } from 'routes/Alliances';
import { Organizations } from 'routes/Organizations';
import { Stops } from 'routes/Stops';
import { ImsRegisters } from 'routes/configuration/imsRegisters/ImsRegisters';
import { StopCodeLists } from 'routes/configuration/stopCodeLists/StopCodeLists';
import { OrganizationCodeLists } from 'routes/configuration/organizationCodeLists/OrganizationCodeLists';
import { CardTypes } from 'routes/configuration/cardTypes/CardTypes';
import AuthWrapper from 'wrappers/AuthWrapper';
import { AppVersion } from '@fleet/shared';
import { BookingFee } from 'routes/bookingFee/BookingFee';

export const Application = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const loading = useSelector(appLoadingSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const { isPlatformUser } = useSelector(loggedInUserSelector);
  const shouldFetchClassifications = useMemo(
    () => isPlatformUser ?? Boolean(currentBusinessEntityId),
    [currentBusinessEntityId, isPlatformUser]
  );

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getCurrentUser());
    }
  }, [auth.isAuthenticated, dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated && shouldFetchClassifications) {
      dispatch(getClassifications());
    }
  }, [auth.isAuthenticated, dispatch, shouldFetchClassifications]);

  return (
    <>
      <Router>
        <AuthWrapper appLoading={loading}>
          <Header />
          <Switch>
            <Route path="/audit/:id?" component={Audit} />
            <Route path="/users/:action(create|edit)?/:id?" component={Users} />
            <Route
              path="/alliances/:action(create|edit)?/:id?"
              component={Alliances}
            />
            <Route path="/organizations" component={Organizations} />
            <Route path="/stops/:action(create|edit)?/:id?" component={Stops} />
            <Route
              path="/booking-fee/:action(create|edit)?/:id?"
              component={BookingFee}
            />
            {isPlatformUser && (
              <Switch>
                <Route
                  path="/configuration/stop-code-lists/:action(create|edit)?/:id?"
                  component={StopCodeLists}
                />
                <Route
                  path="/configuration/ims-registers/:action(create|edit)?/:id?"
                  component={ImsRegisters}
                />
                <Route
                  path="/configuration/organization-code-lists/:action(create|edit)?/:id?"
                  component={OrganizationCodeLists}
                />
                <Route
                  path="/configuration/card-types/:action(create|edit)?/:id?"
                  component={CardTypes}
                />
              </Switch>
            )}
          </Switch>
        </AuthWrapper>
      </Router>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
};
