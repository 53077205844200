import { CardType } from 'dto/cardType';
import { createReducer } from '@reduxjs/toolkit';
import { getCardTypes, setCardType } from 'features/cardType/cardTypeActions';

interface CardTypeState {
  list: Array<CardType>;
  current?: CardType;
}

const initialState: CardTypeState = {
  list: [],
};

export const cardTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCardTypes.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(setCardType, (state, action) => {
      state.current = action.payload;
    });
});
