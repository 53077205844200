import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Button } from '@mui/material';
import { Icon, api } from '@fleet/shared';
import { useSelector } from 'store/utils';
import { userSelector } from 'features/user/userSelectors';
import { useAlert } from 'react-alert';
import { TransButton } from 'i18n/trans/button';
import { TransAlert } from 'i18n/trans/alert';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

interface UserPasswordResetModalProps {}

export const UserPasswordResetModal: FC<UserPasswordResetModalProps> = () => {
  const currentUser = useSelector(userSelector)!;
  const businessEntityId = useSelector(currentBusinessEntityIdSelector);
  const alert = useAlert();
  const organizationId = useMemo(() => {
    return currentUser.organizations.find((org) => org.id === businessEntityId)
      ?.id;
  }, [businessEntityId, currentUser.organizations]);

  const handleSubmit = useCallback(async () => {
    await api.post(`/users/${currentUser.id}/reset-password`, {
      organizationId,
    });
    alert.success(<TransAlert i18nKey="passwordResetLinkSent" />);
  }, [alert, currentUser.id, organizationId]);
  return (
    <>
      <Button
        startIcon={<Icon name="mail" size={16} />}
        size="small"
        onClick={handleSubmit}
        disabled={!organizationId}
      >
        <TransButton i18nKey="sendPasswordResetLink" />
      </Button>
    </>
  );
};
