import { createAsyncThunk } from 'store/utils';
import {
  Stop,
  StopLocalizationPayload,
  StopLocalizationResponse,
  StopPayload,
  StopSearchFilter,
} from 'dto/stop';
import { createAction } from '@reduxjs/toolkit';
import qs from 'qs';
import { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';

export const setStopsFilter = createAction<Partial<StopSearchFilter>>(
  'stop/setStopsFilter'
);

export const clearStopsList = createAction('stop/clearStopsList');

export const getStopsList = createAsyncThunk<
  Pagination<Stop>,
  Partial<StopSearchFilter> | undefined
>('stop/getStopsList', async (values, { dispatch, getState }) => {
  values && dispatch(setStopsFilter(values));
  const { filter } = getState().stops;
  return (
    await api.get(
      `/stops${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data;
});

export const createStop = createAsyncThunk<Stop, StopPayload>(
  'stop/createStop',
  async (stopPayload) => (await api.post('/stops', stopPayload)).data
);

export const updateStop = createAsyncThunk<Stop, StopPayload>(
  'stop/updateStop',
  async ({ id, ...stopPayload }) => {
    return (await api.put(`/stops/${id}`, stopPayload)).data;
  }
);

export const clearStop = createAction('stop/clearStop');

export const getStop = createAsyncThunk<Stop, string>(
  'stop/getStop',
  async (id) => {
    return (await api.get(`/stops/${id}`)).data;
  }
);

export const deleteStop = createAsyncThunk<never, string>(
  'stop/deleteStop',
  async (id) => {
    return await api.delete(`/stops/${id}`);
  }
);

export const deleteStops = createAsyncThunk<void, Array<string>>(
  'stop/deleteSelectedStops',
  async (stopsIds) => {
    await Promise.all(stopsIds.map((id) => api.delete(`/stops/${id}`)));
  }
);

export const addOrUpdateStopLocalization = createAsyncThunk<
  StopLocalizationResponse,
  { stopId: string; payload: StopLocalizationPayload }
>(
  'stop/addOrUpdateLocalization',
  async ({ stopId, payload }) =>
    (
      await (payload.id ? api.put : api.post)(
        payload.id
          ? `/stops/${stopId}/localizations/${payload.id}`
          : `/stops/${stopId}/localizations`,
        payload
      )
    ).data
);

export const deleteLocalizationsStop = createAsyncThunk<void, Array<string>>(
  'stop/deleteLocalizationsStop',
  async (stopLocalizationIds, { getState }) => {
    await Promise.all(
      stopLocalizationIds.map((cultureId) =>
        api.delete(
          `/stops/${getState().stops.current?.id}/localizations/${cultureId}`
        )
      )
    );
  }
);

export const createCode = createAsyncThunk<
  Stop,
  { stopId: string; codeListId: string; code: string }
>('stop/createCode', async ({ stopId, codeListId, code }) => {
  return (await api.put(`/stops/${stopId}/codes/${codeListId}`, { code })).data;
});

export const deleteCode = createAsyncThunk<void, Array<string>>(
  'stop/deleteCode',
  async (codeListIds, { getState }) => {
    await Promise.all(
      codeListIds.map((codeListId) =>
        api.delete(`/stops/${getState().stops.current?.id}/codes/${codeListId}`)
      )
    );
  }
);
