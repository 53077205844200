import { createReducer } from '@reduxjs/toolkit';
import { ImsRegister } from 'dto/imsRegister';
import {
  getImsRegisters,
  updateImsRegister,
  setImsRegister,
} from 'features/imsRegister/imsRegisterActions';

interface ImsRegisterState {
  list: Array<ImsRegister>;
  current?: ImsRegister;
}

const initialState: ImsRegisterState = {
  list: [],
};

export const imsRegisterReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getImsRegisters.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addCase(updateImsRegister.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(setImsRegister, (state, action) => {
      state.current = action.payload;
    });
});
