import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  PeriodField,
  SearchForm,
  SelectField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { OwnerField } from 'components/OwnerField';
import { BookingFeeFilter } from 'dto/bookingFee';
import { ClassificationGroup } from 'dto/classification';
import {
  getBookingFees,
  setBookingFeesFilter,
} from 'features/bookingFee/bookingFeeActions';
import { bookingFeesFilterSelector } from 'features/bookingFee/bookingFeeSelectors';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';

interface BookingFeeSearchFormProps {}

export const BookingFeeSearchForm: FC<BookingFeeSearchFormProps> = () => {
  const dispatch = useDispatch();
  const filter = useSelector(bookingFeesFilterSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const productCategoryOptions = useClassificationOptions(
    ClassificationGroup.PRODUCT_CATEGORY
  );
  const currencyOptions = useClassificationOptions(
    ClassificationGroup.CURRENCY
  );
  const pointOfSaleTypeOptions = useClassificationOptions(
    ClassificationGroup.POINT_OF_SALE_TYPE
  );

  const onSubmit = useCallback(
    async (values: BookingFeeFilter) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getBookingFees({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const initialValues = useMemo<Partial<BookingFeeFilter>>(
    () => ({
      ownerId: currentBusinessEntityId!,
      ...filter,
    }),
    [currentBusinessEntityId, filter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setBookingFeesFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit } = useForm({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setBookingFeesFilter({}));
  }, [dispatch, form]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <OwnerField />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="carrierId"
                label={<TransField i18nKey="carrier" />}
                options={carrierOptions}
                loading
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="pointOfSaleTypeId"
                label={<TransField i18nKey="pointOfSaleType" />}
                options={pointOfSaleTypeOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="productCategoryId"
                label={<TransField i18nKey="productCategory" />}
                options={productCategoryOptions}
                showEmptyOption
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="currencyId"
                label={<TransField i18nKey="currency" />}
                options={currencyOptions}
                showEmptyOption
              />
            </Grid>

            <PeriodField
              from={{
                name: 'validityStart.from',
                label: <TransField i18nKey="validFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'validityStart.to',
                isClearable: true,
              }}
            />
            <PeriodField
              from={{
                name: 'validityEnd.from',
                label: <TransField i18nKey="validTo" />,
                isClearable: true,
              }}
              to={{
                name: 'validityEnd.to',
                isClearable: true,
              }}
            />
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
