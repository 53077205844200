import { createReducer } from '@reduxjs/toolkit';
import { User, UserRoles, UserSearchFilter } from 'dto/user';
import {
  getUser,
  setUser,
  getUsers,
  getRoles,
  setUsersFilter,
  getUserAvailablePasswordPolicies,
  setUserAvailablePasswordPolicies,
  clearUsers,
} from 'features/user/userActions';
import { Pagination } from '@fleet/shared/dto/pagination';
import { Classifier } from '@fleet/shared/dto/classifier';

interface UserState {
  search?: Pagination<User>;
  roles: UserRoles;
  availablePasswordPolicies: Classifier[];
  current?: User;
  filter: Partial<UserSearchFilter>;
}

const initialState: UserState = {
  roles: [],
  availablePasswordPolicies: [],
  filter: {},
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUsersFilter, (state, action) => {
      state.filter = action.payload;
    })
    .addCase(clearUsers, (state) => {
      state.search = undefined;
    })
    .addCase(getUsers.rejected, (state) => {
      state.search = undefined;
    })
    .addCase(getUsers.fulfilled, (state, action) => {
      state.search = action.payload;
    })
    .addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    })
    .addCase(getUser.rejected, (state) => {
      state.current = undefined;
    })
    .addCase(setUser, (state, action) => {
      state.current = action.payload;
    })
    .addCase(getUserAvailablePasswordPolicies.fulfilled, (state, action) => {
      state.availablePasswordPolicies = action.payload;
    })
    .addCase(setUserAvailablePasswordPolicies, (state, action) => {
      state.availablePasswordPolicies = action.payload;
    });
});
