import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  CardHeader,
  Drawer,
  FormProvider,
  TimeDurationField,
  useForm,
} from '@fleet/shared';
import { TextField } from '@fleet/shared/form';
import {
  Button,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import { useHistory, useParams } from 'react-router-dom';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { imsRegisterSelector } from 'features/imsRegister/imsRegisterSelectors';
import { ImsRegisterValues } from 'dto/imsRegister';
import { Scopes } from 'routes/configuration/imsRegisters/Scopes';
import {
  createImsRegister,
  getImsRegisterById,
  getImsRegisters,
  setImsRegister,
  updateImsRegister,
} from 'features/imsRegister/imsRegisterActions';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import {
  convertStringToTimeDuration,
  convertTimeDurationToString,
  TimeUnit,
} from '@fleet/shared/utils/timeDuration';
import { omit } from 'lodash';

const useStyles = makeStyles(
  (theme) => ({
    formFields: {
      '&& > .MuiFormControl-root': {
        '&:not($formSwitch)': {
          marginBottom: 16,
        },

        '& > label': {
          color: theme.palette.text.primary,
          fontSize: 14,
          flexGrow: 1,
        },
      },
    },
    formSwitch: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
      '& .Icon-root': {
        pointerEvents: 'all',
      },
    },
  }),
  {
    name: 'ImsRegisterForm',
  }
);

interface ImsRegisterFormProps {}

export const ImsRegisterForm: FC<ImsRegisterFormProps> = () => {
  const dispatch = useDispatch();
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const currentIms = useSelector(imsRegisterSelector);
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();

  useEffect(() => {
    dispatch(setImsRegister());

    if (action === 'edit' && id) {
      dispatch(getImsRegisterById(id));
    }

    return () => {
      dispatch(setImsRegister());
    };
  }, [action, dispatch, id]);

  const closeDrawer = useCallback(() => {
    history.replace('/configuration/ims-registers');
  }, [history]);

  const onSubmit = useCallback(
    async (values: ImsRegisterValues) => {
      const formattedValues = {
        ...omit(values, 'timeDurationGlobalTimeout'),
        timeouts: {
          global: convertTimeDurationToString(values.timeDurationGlobalTimeout),
        },
      };

      await dispatch(
        (values.id ? updateImsRegister : createImsRegister)(formattedValues)
      ).unwrap();
      alert.success(
        <TransAlert
          i18nKey={values.id ? 'imsRegisterSaved' : 'imsRegisterCreated'}
        />
      );
      closeDrawer();

      await dispatch(getImsRegisters());
    },

    [alert, closeDrawer, dispatch]
  );

  const initialValues = useMemo(() => {
    return {
      ...currentIms,
      timeDurationGlobalTimeout: convertStringToTimeDuration(
        currentIms?.timeouts.global
      ),
    };
  }, [currentIms]);

  const { form, handleSubmit } = useForm<ImsRegisterValues>({
    initialValues,
    onSubmit,
  });

  return (
    <Drawer elevation={0} anchor="right" open onClose={closeDrawer}>
      <FormProvider {...form}>
        <Stack
          sx={{
            width: '400px',
            height: '100vh',
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <CardHeader
            isLight
            title={
              <Typography variant="subtitle">
                <TransTitle i18nKey="ims" />
              </Typography>
            }
            action={
              <IconButton aria-label="close" onClick={closeDrawer}>
                <Tooltip content={<TransButton i18nKey="close" />} delay={500}>
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            }
          />
          <CardContent
            sx={{ px: 3, py: 2, flex: 1, overflowY: 'scroll' }}
            className={classes.formFields}
          >
            <TextField
              label={<TransField i18nKey="name" />}
              name="name"
              required
            />
            <TextField
              label={<TransField i18nKey="apiEndpoint" />}
              name="apiEndpoint"
              required
            />
            <TimeDurationField
              name="timeDurationGlobalTimeout"
              label={<TransField i18nKey="globalTimeouts" />}
              excludedUnits={[TimeUnit.DAYS]}
              required
            />
            <Typography
              variant="subtitle"
              component="h3"
              sx={{ padding: '16px 0 8px' }}
            >
              <TransTitle i18nKey="token" />
            </Typography>
            <TextField
              label={<TransField i18nKey="authorityEndpoint" />}
              name="tokenAuthentication.authorityEndpoint"
              required
            />
            <TextField
              label={<TransField i18nKey="issuer" />}
              name="tokenAuthentication.issuer"
              required
            />
            <TextField
              label={<TransField i18nKey="clientId" />}
              name="tokenAuthentication.clientId"
              required
            />
            <TextField
              label={<TransField i18nKey="clientSecret" />}
              name="tokenAuthentication.clientSecret"
              required
            />
            <Scopes />
          </CardContent>
          <CardActions
            sx={{
              padding: 3,
              justifyContent: 'flex-end',
              boxShadow: 2,
            }}
          >
            <Button variant="text" color="primary" onClick={closeDrawer}>
              <TransButton i18nKey="cancel" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<Icon name={id ? 'check' : 'plus'} />}
            >
              <TransButton i18nKey={id ? 'save' : 'add'} />
            </Button>
          </CardActions>
        </Stack>
      </FormProvider>
    </Drawer>
  );
};
