import { FC, useCallback, useEffect, useMemo } from 'react';
import { CardHeader } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { AddButton, DrawerForm, Layout, Loadable } from '@fleet/shared';
import { useHistory, useParams } from 'react-router-dom';
import { AlliancesForm } from 'routes/alliances/AlliancesForm';
import { getAlliancesList } from 'features/alliance/allianceActions';
import { alliancesListLoadingSelector } from 'features/loading/loadingSelectors';
import { AlliancesTable } from 'routes/alliances/AlliancesTable';
import { TransNav } from 'i18n/trans/header';
import { TransButton } from 'i18n/trans/button';

interface AlliancesProps {}

export const Alliances: FC<AlliancesProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAlliancesList());
  }, [dispatch]);

  const loading = useSelector(alliancesListLoadingSelector);
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const isDrawerOpen = useMemo(
    () => Boolean(action) && (action === 'edit' ? !!id : true),
    [action, id]
  );

  const handleCloseEditForm = useCallback(
    async (event, reason) => {
      if (reason === 'close') {
        history.replace('/alliances');
        await dispatch(getAlliancesList());
      }
    },
    [dispatch, history]
  );

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader title={<TransNav i18nKey="alliances" />}>
            <AddButton onClick={() => history.push('/alliances/create')}>
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <DrawerForm open={isDrawerOpen} onClose={handleCloseEditForm}>
            <AlliancesForm />
          </DrawerForm>
        }
      >
        <AlliancesTable />
      </Layout>
    </Loadable>
  );
};
