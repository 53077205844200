import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  CardHeader,
  ConfirmDeleteModal,
  DrawerForm,
  DrawerFormProps,
  FormProvider,
  Tooltip,
  useForm,
  useLoaded,
  useModal,
} from '@fleet/shared';
import {
  CardContent,
  Grid,
  Stack,
  Typography,
  Button as ButtonMui,
  IconButton,
} from '@mui/material';
import { FormControl, Icon } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { cardTypesLoadingSelector } from 'features/loading/loadingSelectors';
import { useHistory } from 'react-router-dom';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { useParams } from 'react-router';
import { currentCardTypeSelector } from 'features/cardType/cardTypeSelectors';
import { CardTypeFields } from 'routes/configuration/cardTypes/CardTypeFields';
import {
  createOrUpdateCardType,
  deleteCardType,
  getCardType,
  getCardTypes,
  setCardType,
} from 'features/cardType/cardTypeActions';
import { CardType } from 'dto/cardType';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { CardTypeAccordion } from './Accordion/CardTypeAccordion';
import { TransModal } from 'i18n/trans/modal';

interface CardTypeFormProps {}

export const CardTypeForm: FC<CardTypeFormProps> = () => {
  const { action, id } = useParams<{ action: string; id?: string }>();
  const loading = useSelector(cardTypesLoadingSelector);
  const loaded = useLoaded(loading);
  const cardType = useSelector(currentCardTypeSelector);
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const initialValues = useMemo(() => cardType && { ...cardType }, [cardType]);
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );
  const { open: isOpen, onOpen, onClose } = useModal();

  useEffect(() => {
    dispatch(setCardType());
    if (isEditing && id) {
      dispatch(getCardType(id));
    }

    return () => {
      dispatch(setCardType());
    };
  }, [dispatch, id, isEditing]);

  const closeDrawer = useCallback(() => {
    history.replace('/configuration/card-types');
  }, [history]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (_, reason) => {
      if (reason === 'close') {
        dispatch(setCardType());
        closeDrawer();
      }
    },
    [dispatch, closeDrawer]
  );

  const onSubmit = useCallback(
    async ({ type, issuer, ...values }) => {
      const payload = {
        ...values,
        typeId: type.id,
        issuer: issuer?.id,
      };

      await dispatch(createOrUpdateCardType(payload)).unwrap();
      !isEditing && closeDrawer();
      alert.success(
        id ? (
          <TransAlert i18nKey="cardTypeUpdated" />
        ) : (
          <TransAlert i18nKey="cardTypeCreated" />
        )
      );
      await dispatch(getCardTypes());
    },
    [alert, dispatch, id, closeDrawer, isEditing]
  );

  const { form, handleSubmit, dirty, submitting } = useForm<CardType>({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const onCardTypeDelete = useCallback(async () => {
    await dispatch(deleteCardType(id!)).unwrap();

    alert.success(<TransAlert i18nKey="cardTypeDeleted" />);
    closeDrawer();
    await dispatch(getCardTypes());
  }, [dispatch, id, alert, closeDrawer]);

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  useEffect(() => {
    if (!cardType) {
      form.reset({
        isCardNumberMandatory: false,
      });
    }
  }, [cardType, form]);

  return (
    <DrawerForm open onClose={handleCloseEditForm}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <CardHeader
            isLight
            title={
              <Typography variant="subtitle">
                {isEditing ? (
                  cardType?.name
                ) : loading ? (
                  <>&nbsp;</>
                ) : (
                  <TransTitle i18nKey="cardType" />
                )}
              </Typography>
            }
            action={
              <Stack direction="row" alignItems="center">
                <div>
                  {id && (
                    <>
                      <ButtonMui
                        startIcon={<Icon name="trash" />}
                        onClick={onOpen}
                      >
                        <TransButton i18nKey="delete" />
                      </ButtonMui>
                      <ConfirmDeleteModal
                        handleDelete={onCardTypeDelete}
                        title={<TransModal i18nKey="deleteCardType" />}
                        description={
                          <TransModal
                            i18nKey="cardTypeDeletionDescription"
                            values={{ name: cardType?.name }}
                          />
                        }
                        isOpen={isOpen}
                        onClose={onClose}
                      />
                    </>
                  )}
                </div>
                <IconButton aria-label="close" onClick={closeDrawer}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              </Stack>
            }
          />
          <CardContent>
            <Grid container columns={4} spacing={2}>
              <CardTypeFields />
              <Grid item xs="auto" sx={{ ml: 'auto', mt: 6 }}>
                <Stack direction="row" flexWrap="nowrap">
                  <FormControl label="&nbsp;">
                    <Button
                      variant="text"
                      color="primary"
                      sx={{ whiteSpace: 'nowrap' }}
                      {...(!isEditing && { onClick: closeDrawer })}
                      {...(isEditing && {
                        onClick: handleReset,
                        disabled: !dirty,
                      })}
                    >
                      <TransButton
                        i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                      />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button
                      type="submit"
                      variant="contained"
                      icon={id ? 'check' : 'plus'}
                      disabled={submitting}
                    >
                      <TransButton i18nKey={id ? 'save' : 'create'} />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
          {loaded && isEditing && <CardTypeAccordion />}
        </form>
      </FormProvider>
    </DrawerForm>
  );
};
