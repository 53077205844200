import { createSelector, selector } from 'store/utils';

export const selectOrganizations = selector(
  (state) => state.organization.search
);
export const organizationsSelector = createSelector(selectOrganizations);

export const selectCurrentOrganization = selector(
  (state) => state.organization.current
);
export const currentOrganizationSelector = createSelector(
  selectCurrentOrganization
);

export const selectCurrentOrganizationId = selector(
  (state) => state.organization.current!.id
);

export const selectOrganizationsFilter = selector(
  (state) => state.organization.filter
);

export const organizationsFilterSelector = createSelector(
  selectOrganizationsFilter
);

export const selectClientId = selector((state) => state.organization.clientId);
export const clientIdSelector = createSelector(selectClientId);
