import { createSelector, selector } from 'store/utils';

export const selectLoggedInUser = selector((state) => state.common.user);
export const loggedInUserSelector = createSelector(selectLoggedInUser);

export const selectCurrentBusinessEntityId = selector(
  (state) => state.common.currentBusinessEntityId
);
export const currentBusinessEntityIdSelector = createSelector(
  selectCurrentBusinessEntityId
);
