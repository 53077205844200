import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  accountNumber: (
    <Trans i18nKey="table.head.accountNumber" defaults="Account number" />
  ),
  active: <Trans i18nKey="table.head.active" defaults="Active" />,
  assigned: <Trans i18nKey="table.head.assigned" defaults="Assigned" />,
  auditActionType: (
    <Trans i18nKey="table.head.auditActionType" defaults="Event type" />
  ),
  auditExecutionState: (
    <Trans
      i18nKey="table.head.auditExecutionState"
      defaults="State after execution"
    />
  ),
  auditTrailType: (
    <Trans i18nKey="table.head.auditTrailType" defaults="Entity" />
  ),
  bankName: <Trans i18nKey="table.head.bankName" defaults="Bank name" />,
  cancelPermission: (
    <Trans i18nKey="table.head.cancelPermission" defaults="Cancel permission" />
  ),
  carriers: <Trans i18nKey="table.head.carriers" defaults="Carriers" />,
  city: <Trans i18nKey="table.head.city" defaults="City" />,
  cityParish: <Trans i18nKey="table.head.cityParish" defaults="City/Parish" />,
  clearingNumber: (
    <Trans i18nKey="table.head.clearingNumber" defaults="Clearing number" />
  ),
  code: <Trans i18nKey="table.head.code" defaults="Code" />,
  contactInfo: (
    <Trans i18nKey="table.head.contactInfo" defaults="Contact info" />
  ),
  country: <Trans i18nKey="table.head.country" defaults="Country" />,
  county: <Trans i18nKey="table.head.county" defaults="County" />,
  currency: <Trans i18nKey="table.head.currency" defaults="Currency" />,
  dateTime: <Trans i18nKey="table.head.dateTime" defaults="Date and time" />,
  description: (
    <Trans i18nKey="table.head.description" defaults="Description" />
  ),
  email: <Trans i18nKey="table.head.email" defaults="Email" />,
  externalReference: (
    <Trans
      i18nKey="table.head.externalReference"
      defaults="External Reference"
    />
  ),
  file: <Trans i18nKey="table.head.file" defaults="File" />,
  fileType: <Trans i18nKey="table.head.fileType" defaults="File type" />,
  giroNumber: <Trans i18nKey="table.head.giroNumber" defaults="Giro number" />,
  iban: <Trans i18nKey="table.head.iban" defaults="IBAN" />,
  id: <Trans i18nKey="table.head.id" defaults="ID" />,
  inActive: <Trans i18nKey="table.head.inActive" defaults="Inactive" />,
  isActive: <Trans i18nKey="table.head.isActive" defaults="Is active" />,
  language: <Trans i18nKey="table.head.language" defaults="Language" />,
  latitude: <Trans i18nKey="field.latitude" defaults="Latitude" />,
  logo: <Trans i18nKey="table.head.logo" defaults="Logo" />,
  longitude: <Trans i18nKey="field.longitude" defaults="Longitude" />,
  members: <Trans i18nKey="table.head.members" defaults="Members" />,
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  organization: (
    <Trans i18nKey="table.head.organization" defaults="Organization" />
  ),
  organizations: (
    <Trans i18nKey="table.head.organizations" defaults="Organizations" />
  ),
  phone: <Trans i18nKey="table.head.phone" defaults="Phone" />,
  pointOfSaleType: (
    <Trans i18nKey="table.head.pointOfSaleType" defaults="Point of sale type" />
  ),
  pointsOfSales: (
    <Trans i18nKey="table.head.pointsOfSales" defaults="Points of sales" />
  ),
  priority: <Trans i18nKey="table.head.priority" defaults="Priority" />,
  productCategory: (
    <Trans i18nKey="table.head.productCategory" defaults="Product category" />
  ),
  purpose: <Trans i18nKey="table.head.purpose" defaults="Purpose" />,
  regionalRestrictions: (
    <Trans
      i18nKey="table.head.regionalRestrictions"
      defaults="Regional restrictions"
    />
  ),
  registrationCode: (
    <Trans i18nKey="table.head.registrationCode" defaults="Registration code" />
  ),
  retailer: <Trans i18nKey="table.head.retailer" defaults="Retailer" />,
  role: <Trans i18nKey="table.head.role" defaults="Role" />,
  roles: <Trans i18nKey="table.head.roles" defaults="Roles" />,
  salesPoint: <Trans i18nKey="table.head.salesPoint" defaults="Sales point" />,
  stopCodeListId: (
    <Trans i18nKey="table.head.stopCodeListId" defaults="Code list" />
  ),
  stopName: <Trans i18nKey="table.head.stopName" defaults="Stop name" />,
  street: <Trans i18nKey="table.head.street" defaults="Street" />,
  streetHouseNumber: (
    <Trans
      i18nKey="table.head.streetHouseNumber"
      defaults="Street, house number"
    />
  ),
  termsAndConditions: (
    <Trans
      i18nKey="table.head.termsAndConditions"
      defaults="Terms and conditions"
    />
  ),
  text: <Trans i18nKey="table.head.text" defaults="Text" />,
  thresholds: <Trans i18nKey="table.head.thresholds" defaults="Thresholds" />,
  type: <Trans i18nKey="table.head.type" defaults="Type" />,
  typeOfTool: <Trans i18nKey="table.head.typeOfTool" defaults="Type of tool" />,
  unit: <Trans i18nKey="table.head.unit" defaults="Unit" />,
  user: <Trans i18nKey="table.head.user" defaults="User" />,
  username: <Trans i18nKey="table.head.username" defaults="Username" />,
  validFrom: <Trans i18nKey="table.head.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="table.head.validTo" defaults="Valid to" />,
  value: <Trans i18nKey="table.head.value" defaults="Value" />,
  vatRate: <Trans i18nKey="table.head.vatRate" defaults="VAT rate" />,
  viewPermission: (
    <Trans i18nKey="table.head.viewPermission" defaults="View permission" />
  ),
  zipCode: <Trans i18nKey="table.head.zipCode" defaults="Zip code" />,
});
