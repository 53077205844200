import type { FC, MouseEventHandler } from 'react';
import { useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Icon } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import {
  getAuditTrailState,
  setAuditTrailState,
} from 'features/audit/auditActions';
import { auditTrailStateSelector } from 'features/audit/auditSelectors';
import { AuditStateModal } from 'routes/audit/AuditStateModal';
import { TransModal } from 'i18n/trans/modal';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles(
  () => ({
    root: {
      textDecoration: 'none',
      '& .MuiButton-root, .Icon-root': {
        color: 'inherit',
      },
    },
  }),
  {
    name: 'AuditState',
  }
);

interface AuditStateProps {
  id: string;
}

const parse = (value: string) => {
  let parsed = '';
  try {
    parsed = JSON.stringify(JSON.parse(value), null, 2);
  } catch (e) {}
  return parsed;
};

export const AuditState: FC<AuditStateProps> = ({ id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleGetAuditState = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (event) => {
      event.preventDefault();
      await dispatch(getAuditTrailState(event.currentTarget.id)).unwrap();
      history.replace(`/audit/${id}`);
    },
    [dispatch, history, id]
  );

  const handleCloseAuditStateModal = useCallback(() => {
    dispatch(setAuditTrailState(undefined));
    history.replace('/audit');
  }, [dispatch, history]);

  const auditTrailState = useSelector(auditTrailStateSelector);
  const state = useMemo(() => {
    const state = { origin: '', value: '' };
    if (auditTrailState && auditTrailState.id === id) {
      if (auditTrailState.previousState) {
        state.origin = parse(auditTrailState.previousState);
      }
      if (auditTrailState.state) {
        state.value = parse(auditTrailState.state);
      }
    }
    return state;
  }, [auditTrailState, id]);

  const classes = useStyles();

  return (
    <Link to="/audit" className={classes.root}>
      <Button
        id={id}
        startIcon={<Icon name="visibility-on" />}
        sx={{ px: 0 }}
        onClick={handleGetAuditState}
      >
        <TransButton i18nKey="view" />
      </Button>
      {auditTrailState && auditTrailState.id === id && (
        <AuditStateModal
          open={true}
          title={<TransModal i18nKey="auditExecutionState" />}
          actionButton={<div />}
          orig={state.origin}
          value={state.value}
          onClose={handleCloseAuditStateModal}
        />
      )}
    </Link>
  );
};
